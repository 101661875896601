@import "@left4code/tw-starter/dist/css/mixins/_media.css";

.icewall {
    .wrapper {
        /* &:before {
            animation: 0.4s intro-wrapper ease-in-out 0.1s;
            animation-fill-mode: forwards;
        }
        .wrapper-box {
            animation: 0.4s intro-wrapper ease-in-out 0.2s;
            animation-fill-mode: forwards;
        } */
    }
    .top-nav {
        /* animation: 0.4s intro-top-menu ease-in-out 0.3s;
        animation-fill-mode: forwards; */
        /* opacity: 1; */
        /* transform: translateY(0px); */ 
        .top-menu {
            height: 55px;
            display: flex;
            align-items: center;
            /* padding-left: theme("spacing.5");
            padding-right: theme("spacing.5"); */
            margin-right: 30px;
            color: #A1A5B7;
            font-weight: 500;
            line-height: 14px;
            position: relative;
            /* border-radius: theme("borderRadius.full"); */
            @media screen(xl) {
                height: 47px;
                border-radius: theme("borderRadius.lg");
            }
            .top-menu__icon {
                z-index: 10;
            }
            .top-menu__title {
                width: 100%;
                margin-left: theme("spacing.3");
                display: flex;
                align-items: center;
                white-space: nowrap;
                z-index: 10;
                .top-menu__sub-icon {
                    transition-property: theme("transitionProperty.DEFAULT");
                    transition-timing-function: theme(
                        "transitionTimingFunction.in"
                    );
                    transition-duration: theme("transitionDuration.100");
                    width: theme("spacing.4");
                    height: theme("spacing.4");
                    display: none;
                    @media screen(xl) {
                        display: block;
                    }
                }
            }
        }
        & > ul {
            @for $i from 1 through 50 {
                > li:nth-child($i) {
                    & > a {
                        &:not(.top-menu--active) {
                            /* opacity: 0; */
                            /* transform: translateY(50px); */
                            /* animation: 0.4s
                                intro-menu-animation
                                ease-in-out
                                0.33333s;
                            animation-fill-mode: forwards;
                            animation-delay: calc($i * 0.1s); */
                        }
                    }
                }
            }
            & > li {
                /* First level */
                &:hover {
                    position: relative;
                    & > .top-menu {
                        color: white;
                        
                        /* background: theme("colors.theme.1"/ 60%); */
                        &:before {
                            content: "";
                            display: block;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            /* background-color: theme("colors.white" / 4%); */
                            border-radius: theme("borderRadius.full");
                            position: absolute;
                            z-index: -1;
                            @media screen(xl) {
                              
                                /* background-color: theme("colors.white" / 10%); */
                                border-radius: theme("borderRadius.lg");
                            }
                        }
                        .top-menu__title {
                            .top-menu__sub-icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    & > ul {
                        display: block;
                    }
                }
                & > .top-menu {
                    margin-top: 3px;
                    &.top-menu--active {
                        /* background-color: theme("colors.slate.100"); */
                        
                        @media screen(xl) {
                            /* background-color: theme("colors.theme.1"); */
                            
                        }
                        &:before {
                            content: "";
                            display: none;
                            top: 57px;
                            height: 1px;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            /* background-color: theme("colors.white" / 8%); */
                            background-color: theme("colors.white");
                            
                            border-radius: theme("borderRadius.lg");
                            position: absolute;
                            border-bottom: 3px solid theme("colors.black" / 10%);
                            @media screen(xl) {
                                display: block;
                            }
                        }
                        &:after {
                            content: "";
                            /* animation: 0.3s ease-in-out 1s
                                active-top-menu-chevron;
                            animation-fill-mode: forwards; */
                            display: none;
                            width: 20px;
                            height: 80px;
                            margin-bottom: -74px;
                            /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23f1f5f8'/%3E%3C/svg%3E"); */
                            background-repeat: no-repeat;
                            background-size: cover;

                            
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin-left: auto;
                            margin-right: auto;
                            transform: rotate(90deg);
                            opacity: 0;
                            @media screen(xl) {
                                display: block;
                            }
                        }
                        .top-menu__icon {
                            color: theme("colors.theme.1");
                            @media screen(xl) {
                                color: white;
                            }
                        }
                        .top-menu__title {
                            font-weight: theme("fontWeight.medium");
                            color: theme("colors.slate.800");
                            @media screen(xl) {
                                color: white;
                            }
                        }
                    }
                    .top-menu__icon {
                        margin-top: -3px;
                    }
                    .top-menu__title {
                        margin-top: -3px;
                        .top-menu__sub-icon {
                            margin-left: theme("spacing.2");
                        }
                    }
                }
                & > ul {
                    box-shadow: 0px 3px 20px #0000000b;
                    background-color: theme("colors.theme.1");
                    background-color: black;
               
                    display: none;
                    width: theme("spacing.56");
                    position: absolute;
                    border-radius: theme("borderRadius.md");
                    z-index: 20;
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: theme("spacing.1");
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: theme("colors.white" / 4%);
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: theme("borderRadius.md");
                        z-index: -1;
                    }
                    &:after {
                        content: "";
                        width: 100%;
                        height: theme("spacing.1");
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin-top: calc(theme("spacing.1") * -1);
                        cursor: pointer;
                    }
                    & > li {
                        /* Second level */
                        padding-left: theme("spacing.5");
                        padding-right: theme("spacing.5");
                        position: relative;
                        &:hover {
                            background-color: theme("colors.white" / 10%);
                            position: relative;
                            & > .top-menu {
                               
                                .top-menu__title {
                                    .top-menu__sub-icon {
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                            & > ul {
                                display: block;
                            }
                        }
                        & > .top-menu {
                       
                            padding-left: 0;
                            padding-right: 0;
                            margin-right: 0;
                            .top-menu__title {
                                width: 100%;
                                .top-menu__sub-icon {
                                    margin-left: auto;
                                }
                            }
                        }
                        & > ul {
                            box-shadow: 0px 3px 20px #0000000b;
                            left: 100%;
                            background-color: theme("colors.theme.1");
                            background-color: black;
                            
                            display: none;
                            border-radius: theme("borderRadius.md");
                            margin-top: 0;
                            margin-left: 0;
                            top: 0;
                            width: theme("spacing.56");
                            position: absolute;
                            z-index: 20;
                            padding-left: 0;
                            padding-right: 0;
                            &:before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: theme("colors.white" / 4%);
                                
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: theme("borderRadius.md");
                                z-index: -1;
                            }
                           > :hover{
                                    background-color: theme("colors.white" / 10%);
                            }
                            & > li {
                                /* Third level */
                                padding-left: theme("spacing.5");
                                padding-right: theme("spacing.5");
                             
                                & > .top-menu {
                                    padding-left: 0;
                                    padding-right: 0;
                                    margin-right: 0;
                                    .top-menu__title {
                                        width: 100%;
                                        .top-menu__sub-icon {
                                            margin-left: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* @keyframes active-top-menu-chevron {
    100% {
        opacity: 1;
        margin-bottom: -54px;
    }
}

@keyframes intro-wrapper {
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes intro-top-menu {
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes intro-menu-animation {
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
} */

.dark {
    .icewall {
       
        .top-nav {
            .top-menu {
                .top-menu__icon {
                    color: theme("colors.slate.400");
                }
                .top-menu__title {
                    color: theme("colors.slate.400");
                }
            }
            & > ul {
                & > li {
                    /* First level */
                    &:hover {
                        & > .top-menu {
                            &:not(.top-menu--active) {
                                background-color: transparent;
                                &:before {
                                    background-color: theme(
                                        "colors.darkmode.700"
                                    );
                                }
                            }
                        }
                    }
                    & > .top-menu {
                        &.top-menu--active {
                            background-color: theme("colors.darkmode.700");
                            &:before {
                                background-color: theme("colors.darkmode.700");
                            }
                            &:after {
                                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23232e45'/%3E%3C/svg%3E");
                            }
                            .top-menu__icon {
                                color: theme("colors.white");
                            }
                            .top-menu__title {
                                color: theme("colors.white");
                            }
                        }
                    }
                    & > ul {
                        background-color: theme("colors.darkmode.600");
                        box-shadow: 0px 3px 7px #0000001c;
                        &:before {
                            background-color: theme("colors.black" / 10%);
                        }
                        & > li {
                            /* Second level */
                            & > ul {
                                background-color: theme("colors.darkmode.600");
                                box-shadow: 0px 3px 7px #0000001c;
                                &:before {
                                    background-color: theme(
                                        "colors.black" / 10%
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
