@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/dist/svg-arrow.css";
@import "tippy.js/animations/shift-away.css";

.tooltip-content {
    left: 10000px;
    position: fixed;
}
.tippy-box {
    border-radius: theme("borderRadius.md");
    .tippy-content {
        padding: theme("spacing.[1.5]") theme("spacing.3");
    }
}
.tippy-popper {
    &[x-placement="left"],
    &[x-placement="right"],
    &[x-placement="left-start"],
    &[x-placement="left-end"],
    &[x-placement="right-start"],
    &[x-placement="right-end"] {
        .tippy-roundarrow,
        .tippy-arrow {
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    &[x-placement="top"],
    &[x-placement="bottom"],
    &[x-placement="top-start"],
    &[x-placement="top-end"],
    &[x-placement="bottom-start"],
    &[x-placement="bottom-end"] {
        .tippy-roundarrow,
        .tippy-arrow {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.dark {
    .tippy-box {
        box-shadow: 0 0 20px 4px #00000026, 0 4px 80px -8px #24282f40,
            0 4px 4px -2px #5b5e6926;
        color: theme("colors.slate.300");
        background-color: theme("colors.darkmode.300");
        & > .tippy-svg-arrow {
            fill: theme("colors.darkmode.300");
        }
    }
}
