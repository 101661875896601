@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEz0dL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEzQdL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEzwdL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEzMdL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEz8dL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEz4dL-vwnYh2eg.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEzAdL-vwnYg.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc3CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc-CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc2CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc5CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc1CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc0CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc6CsTYl4BO.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc3CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc-CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc2CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc5CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc1CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc0CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc6CsTYl4BO.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic3CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic-CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic2CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic5CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic1CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic0CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc3CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc-CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc2CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc5CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc1CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc0CsTYl4BOQ3o.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc6CsTYl4BO.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxFIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxEIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxLIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxHIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxGIzIXKMnyrYk.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCRc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfABc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCBc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfBxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCxc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfChc4AMP6lbBP.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

html.dark body {
    color: #cbd5e1
}

html.dark body *,
html.dark body :before,
html.dark body :after {
    border-color: #ffffff0d
}

html body {
    letter-spacing: .025em;
    font-size: .875rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Roboto;
    color: #475569;
    line-height: 1.25rem
}

html ::-webkit-scrollbar {
    height: 9px
}

html ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px
}

html ::-webkit-scrollbar-track {
    background-color: #f1f1f1
}

html {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1
}

html ::-moz-scrollbar-thumb {
    background-color: #888
}

html ::-moz-scrollbar-track {
    background-color: #f1f1f1
}

html {
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1
}

html body::-webkit-scrollbar-thumb {
    background-color: #888
}

html body::-webkit-scrollbar-track {
    background-color: #f1f1f1
}

html body:hover::-webkit-scrollbar-thumb {
    background-color: #888
}

html body:hover::-webkit-scrollbar-track {
    background-color: #f1f1f1
}

*>.intro-x:nth-child(1) {
    z-index: 49;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

*>.-intro-x:nth-child(1) {
    z-index: 49;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

*>.intro-y:nth-child(1) {
    z-index: 49;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

*>.-intro-y:nth-child(1) {
    z-index: 49;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

*>.intro-x:nth-child(2) {
    z-index: 48;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

*>.-intro-x:nth-child(2) {
    z-index: 48;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

*>.intro-y:nth-child(2) {
    z-index: 48;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

*>.-intro-y:nth-child(2) {
    z-index: 48;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

*>.intro-x:nth-child(3) {
    z-index: 47;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

*>.-intro-x:nth-child(3) {
    z-index: 47;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

*>.intro-y:nth-child(3) {
    z-index: 47;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

*>.-intro-y:nth-child(3) {
    z-index: 47;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

*>.intro-x:nth-child(4) {
    z-index: 46;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

*>.-intro-x:nth-child(4) {
    z-index: 46;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

*>.intro-y:nth-child(4) {
    z-index: 46;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

*>.-intro-y:nth-child(4) {
    z-index: 46;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

*>.intro-x:nth-child(5) {
    z-index: 45;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

*>.-intro-x:nth-child(5) {
    z-index: 45;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

*>.intro-y:nth-child(5) {
    z-index: 45;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

*>.-intro-y:nth-child(5) {
    z-index: 45;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

*>.intro-x:nth-child(6) {
    z-index: 44;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

*>.-intro-x:nth-child(6) {
    z-index: 44;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

*>.intro-y:nth-child(6) {
    z-index: 44;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

*>.-intro-y:nth-child(6) {
    z-index: 44;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

*>.intro-x:nth-child(7) {
    z-index: 43;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

*>.-intro-x:nth-child(7) {
    z-index: 43;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

*>.intro-y:nth-child(7) {
    z-index: 43;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

*>.-intro-y:nth-child(7) {
    z-index: 43;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

*>.intro-x:nth-child(8) {
    z-index: 42;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

*>.-intro-x:nth-child(8) {
    z-index: 42;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

*>.intro-y:nth-child(8) {
    z-index: 42;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

*>.-intro-y:nth-child(8) {
    z-index: 42;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

*>.intro-x:nth-child(9) {
    z-index: 41;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

*>.-intro-x:nth-child(9) {
    z-index: 41;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

*>.intro-y:nth-child(9) {
    z-index: 41;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

*>.-intro-y:nth-child(9) {
    z-index: 41;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

*>.intro-x:nth-child(10) {
    z-index: 40;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

*>.-intro-x:nth-child(10) {
    z-index: 40;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

*>.intro-y:nth-child(10) {
    z-index: 40;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

*>.-intro-y:nth-child(10) {
    z-index: 40;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

*>.intro-x:nth-child(11) {
    z-index: 39;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

*>.-intro-x:nth-child(11) {
    z-index: 39;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

*>.intro-y:nth-child(11) {
    z-index: 39;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

*>.-intro-y:nth-child(11) {
    z-index: 39;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

*>.intro-x:nth-child(12) {
    z-index: 38;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

*>.-intro-x:nth-child(12) {
    z-index: 38;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

*>.intro-y:nth-child(12) {
    z-index: 38;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

*>.-intro-y:nth-child(12) {
    z-index: 38;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

*>.intro-x:nth-child(13) {
    z-index: 37;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

*>.-intro-x:nth-child(13) {
    z-index: 37;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

*>.intro-y:nth-child(13) {
    z-index: 37;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

*>.-intro-y:nth-child(13) {
    z-index: 37;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

*>.intro-x:nth-child(14) {
    z-index: 36;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

*>.-intro-x:nth-child(14) {
    z-index: 36;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

*>.intro-y:nth-child(14) {
    z-index: 36;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

*>.-intro-y:nth-child(14) {
    z-index: 36;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

*>.intro-x:nth-child(15) {
    z-index: 35;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

*>.-intro-x:nth-child(15) {
    z-index: 35;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

*>.intro-y:nth-child(15) {
    z-index: 35;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

*>.-intro-y:nth-child(15) {
    z-index: 35;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

*>.intro-x:nth-child(16) {
    z-index: 34;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

*>.-intro-x:nth-child(16) {
    z-index: 34;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

*>.intro-y:nth-child(16) {
    z-index: 34;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

*>.-intro-y:nth-child(16) {
    z-index: 34;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

*>.intro-x:nth-child(17) {
    z-index: 33;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

*>.-intro-x:nth-child(17) {
    z-index: 33;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

*>.intro-y:nth-child(17) {
    z-index: 33;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

*>.-intro-y:nth-child(17) {
    z-index: 33;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

*>.intro-x:nth-child(18) {
    z-index: 32;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

*>.-intro-x:nth-child(18) {
    z-index: 32;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

*>.intro-y:nth-child(18) {
    z-index: 32;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

*>.-intro-y:nth-child(18) {
    z-index: 32;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

*>.intro-x:nth-child(19) {
    z-index: 31;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

*>.-intro-x:nth-child(19) {
    z-index: 31;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

*>.intro-y:nth-child(19) {
    z-index: 31;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

*>.-intro-y:nth-child(19) {
    z-index: 31;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

*>.intro-x:nth-child(20) {
    z-index: 30;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

*>.-intro-x:nth-child(20) {
    z-index: 30;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

*>.intro-y:nth-child(20) {
    z-index: 30;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

*>.-intro-y:nth-child(20) {
    z-index: 30;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

*>.intro-x:nth-child(21) {
    z-index: 29;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

*>.-intro-x:nth-child(21) {
    z-index: 29;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

*>.intro-y:nth-child(21) {
    z-index: 29;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

*>.-intro-y:nth-child(21) {
    z-index: 29;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

*>.intro-x:nth-child(22) {
    z-index: 28;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

*>.-intro-x:nth-child(22) {
    z-index: 28;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

*>.intro-y:nth-child(22) {
    z-index: 28;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

*>.-intro-y:nth-child(22) {
    z-index: 28;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

*>.intro-x:nth-child(23) {
    z-index: 27;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

*>.-intro-x:nth-child(23) {
    z-index: 27;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

*>.intro-y:nth-child(23) {
    z-index: 27;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

*>.-intro-y:nth-child(23) {
    z-index: 27;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

*>.intro-x:nth-child(24) {
    z-index: 26;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

*>.-intro-x:nth-child(24) {
    z-index: 26;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

*>.intro-y:nth-child(24) {
    z-index: 26;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

*>.-intro-y:nth-child(24) {
    z-index: 26;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

*>.intro-x:nth-child(25) {
    z-index: 25;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

*>.-intro-x:nth-child(25) {
    z-index: 25;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

*>.intro-y:nth-child(25) {
    z-index: 25;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

*>.-intro-y:nth-child(25) {
    z-index: 25;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

*>.intro-x:nth-child(26) {
    z-index: 24;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

*>.-intro-x:nth-child(26) {
    z-index: 24;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

*>.intro-y:nth-child(26) {
    z-index: 24;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

*>.-intro-y:nth-child(26) {
    z-index: 24;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

*>.intro-x:nth-child(27) {
    z-index: 23;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

*>.-intro-x:nth-child(27) {
    z-index: 23;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

*>.intro-y:nth-child(27) {
    z-index: 23;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

*>.-intro-y:nth-child(27) {
    z-index: 23;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

*>.intro-x:nth-child(28) {
    z-index: 22;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

*>.-intro-x:nth-child(28) {
    z-index: 22;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

*>.intro-y:nth-child(28) {
    z-index: 22;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

*>.-intro-y:nth-child(28) {
    z-index: 22;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

*>.intro-x:nth-child(29) {
    z-index: 21;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

*>.-intro-x:nth-child(29) {
    z-index: 21;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

*>.intro-y:nth-child(29) {
    z-index: 21;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

*>.-intro-y:nth-child(29) {
    z-index: 21;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

*>.intro-x:nth-child(30) {
    z-index: 20;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

*>.-intro-x:nth-child(30) {
    z-index: 20;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

*>.intro-y:nth-child(30) {
    z-index: 20;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

*>.-intro-y:nth-child(30) {
    z-index: 20;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

*>.intro-x:nth-child(31) {
    z-index: 19;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

*>.-intro-x:nth-child(31) {
    z-index: 19;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

*>.intro-y:nth-child(31) {
    z-index: 19;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

*>.-intro-y:nth-child(31) {
    z-index: 19;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

*>.intro-x:nth-child(32) {
    z-index: 18;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

*>.-intro-x:nth-child(32) {
    z-index: 18;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

*>.intro-y:nth-child(32) {
    z-index: 18;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

*>.-intro-y:nth-child(32) {
    z-index: 18;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

*>.intro-x:nth-child(33) {
    z-index: 17;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

*>.-intro-x:nth-child(33) {
    z-index: 17;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

*>.intro-y:nth-child(33) {
    z-index: 17;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

*>.-intro-y:nth-child(33) {
    z-index: 17;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

*>.intro-x:nth-child(34) {
    z-index: 16;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

*>.-intro-x:nth-child(34) {
    z-index: 16;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

*>.intro-y:nth-child(34) {
    z-index: 16;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

*>.-intro-y:nth-child(34) {
    z-index: 16;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

*>.intro-x:nth-child(35) {
    z-index: 15;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

*>.-intro-x:nth-child(35) {
    z-index: 15;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

*>.intro-y:nth-child(35) {
    z-index: 15;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

*>.-intro-y:nth-child(35) {
    z-index: 15;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

*>.intro-x:nth-child(36) {
    z-index: 14;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

*>.-intro-x:nth-child(36) {
    z-index: 14;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

*>.intro-y:nth-child(36) {
    z-index: 14;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

*>.-intro-y:nth-child(36) {
    z-index: 14;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

*>.intro-x:nth-child(37) {
    z-index: 13;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

*>.-intro-x:nth-child(37) {
    z-index: 13;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

*>.intro-y:nth-child(37) {
    z-index: 13;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

*>.-intro-y:nth-child(37) {
    z-index: 13;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

*>.intro-x:nth-child(38) {
    z-index: 12;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

*>.-intro-x:nth-child(38) {
    z-index: 12;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

*>.intro-y:nth-child(38) {
    z-index: 12;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

*>.-intro-y:nth-child(38) {
    z-index: 12;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

*>.intro-x:nth-child(39) {
    z-index: 11;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

*>.-intro-x:nth-child(39) {
    z-index: 11;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

*>.intro-y:nth-child(39) {
    z-index: 11;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

*>.-intro-y:nth-child(39) {
    z-index: 11;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

*>.intro-x:nth-child(40) {
    z-index: 10;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

*>.-intro-x:nth-child(40) {
    z-index: 10;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

*>.intro-y:nth-child(40) {
    z-index: 10;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

*>.-intro-y:nth-child(40) {
    z-index: 10;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

*>.intro-x:nth-child(41) {
    z-index: 9;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

*>.-intro-x:nth-child(41) {
    z-index: 9;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

*>.intro-y:nth-child(41) {
    z-index: 9;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

*>.-intro-y:nth-child(41) {
    z-index: 9;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

*>.intro-x:nth-child(42) {
    z-index: 8;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

*>.-intro-x:nth-child(42) {
    z-index: 8;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

*>.intro-y:nth-child(42) {
    z-index: 8;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

*>.-intro-y:nth-child(42) {
    z-index: 8;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

*>.intro-x:nth-child(43) {
    z-index: 7;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

*>.-intro-x:nth-child(43) {
    z-index: 7;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

*>.intro-y:nth-child(43) {
    z-index: 7;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

*>.-intro-y:nth-child(43) {
    z-index: 7;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

*>.intro-x:nth-child(44) {
    z-index: 6;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

*>.-intro-x:nth-child(44) {
    z-index: 6;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

*>.intro-y:nth-child(44) {
    z-index: 6;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

*>.-intro-y:nth-child(44) {
    z-index: 6;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

*>.intro-x:nth-child(45) {
    z-index: 5;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

*>.-intro-x:nth-child(45) {
    z-index: 5;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

*>.intro-y:nth-child(45) {
    z-index: 5;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

*>.-intro-y:nth-child(45) {
    z-index: 5;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

*>.intro-x:nth-child(46) {
    z-index: 4;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

*>.-intro-x:nth-child(46) {
    z-index: 4;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

*>.intro-y:nth-child(46) {
    z-index: 4;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

*>.-intro-y:nth-child(46) {
    z-index: 4;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

*>.intro-x:nth-child(47) {
    z-index: 3;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

*>.-intro-x:nth-child(47) {
    z-index: 3;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

*>.intro-y:nth-child(47) {
    z-index: 3;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

*>.-intro-y:nth-child(47) {
    z-index: 3;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

*>.intro-x:nth-child(48) {
    z-index: 2;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

*>.-intro-x:nth-child(48) {
    z-index: 2;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

*>.intro-y:nth-child(48) {
    z-index: 2;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

*>.-intro-y:nth-child(48) {
    z-index: 2;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

*>.intro-x:nth-child(49) {
    z-index: 1;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

*>.-intro-x:nth-child(49) {
    z-index: 1;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

*>.intro-y:nth-child(49) {
    z-index: 1;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

*>.-intro-y:nth-child(49) {
    z-index: 1;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

*>.intro-x:nth-child(50) {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translate(50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

*>.-intro-x:nth-child(50) {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translate(-50px);
    animation: .4s intro-x-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

*>.intro-y:nth-child(50) {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

*>.-intro-y:nth-child(50) {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: .4s intro-y-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

@keyframes intro-x-animation {
    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes intro-y-animation {
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

:before,
:after {
    --tw-content: ""
}

html,
:host {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
input:where([type=button]),
input:where([type=reset]),
input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

dialog {
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

[type=text],
input:where(:not([type])),
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

[type=text]:focus,
input:where(:not([type])):focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1
}

input::placeholder,
textarea::placeholder {
    color: #6b7280;
    opacity: 1
}

::-webkit-datetime-edit-fields-wrapper {
    padding: 0
}

::-webkit-date-and-time-value {
    min-height: 1.5em;
    text-align: inherit
}

::-webkit-datetime-edit {
    display: inline-flex
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

[multiple],
[size]:where(select:not([size="1"])) {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: .75rem;
    -webkit-print-color-adjust: unset;
    print-color-adjust: unset
}

[type=checkbox],
[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

[type=checkbox] {
    border-radius: 0
}

[type=radio] {
    border-radius: 100%
}

[type=checkbox]:focus,
[type=radio]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)
}

[type=checkbox]:checked,
[type=radio]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

@media (forced-colors: active) {
    [type=checkbox]:checked {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto
    }
}

[type=radio]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

@media (forced-colors: active) {
    [type=radio]:checked {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto
    }
}

[type=checkbox]:checked:hover,
[type=checkbox]:checked:focus,
[type=radio]:checked:hover,
[type=radio]:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=checkbox]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

@media (forced-colors: active) {
    [type=checkbox]:indeterminate {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto
    }
}

[type=checkbox]:indeterminate:hover,
[type=checkbox]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=file] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit
}

[type=file]:focus {
    outline: 1px solid ButtonText;
    outline: 1px auto -webkit-focus-ring-color
}

:root {
    --color-theme-1: 18 18 18;
    --color-theme-2: 18 18 18;
    --color-primary: 62 151 255;
    --color-secondary: 226 232 240;
    --color-success: 71 190 125;
    --color-info: 6 182 212;
    --color-warning: 250 204 21;
    --color-pending: 249 115 22;
    --color-danger: 220 38 38;
    --color-light: 241 245 249;
    --color-dark: 30 41 59
}

.dark {
    --color-primary: 29 78 216;
    --color-darkmode-50: 87 103 132;
    --color-darkmode-100: 74 90 121;
    --color-darkmode-200: 65 81 114;
    --color-darkmode-300: 53 69 103;
    --color-darkmode-400: 48 61 93;
    --color-darkmode-500: 41 53 82;
    --color-darkmode-600: 40 51 78;
    --color-darkmode-700: 35 45 69;
    --color-darkmode-800: 27 37 59;
    --color-darkmode-900: 15 23 42
}

.theme-1 {
    --color-theme-1: 6 95 70;
    --color-theme-2: 6 78 59;
    --color-primary: 6 78 59;
    --color-secondary: 226 232 240;
    --color-success: 5 150 105;
    --color-info: 6 182 212;
    --color-warning: 250 204 21;
    --color-pending: 245 158 11;
    --color-danger: 225 29 72;
    --color-light: 241 245 249;
    --color-dark: 30 41 59
}

.theme-1.dark {
    --color-primary: 6 95 70
}

.theme-2 {
    --color-theme-1: 30 58 138;
    --color-theme-2: 23 37 84;
    --color-primary: 23 37 84;
    --color-secondary: 226 232 240;
    --color-success: 13 148 136;
    --color-info: 6 182 212;
    --color-warning: 245 158 11;
    --color-pending: 249 115 22;
    --color-danger: 185 28 28;
    --color-light: 241 245 249;
    --color-dark: 30 41 59
}

.theme-2.dark {
    --color-primary: 30 64 175
}

.theme-3 {
    --color-theme-1: 21 94 117;
    --color-theme-2: 22 78 99;
    --color-primary: 22 78 99;
    --color-secondary: 226 232 240;
    --color-success: 13 148 136;
    --color-info: 6 182 212;
    --color-warning: 245 158 11;
    --color-pending: 217 119 6;
    --color-danger: 185 28 28;
    --color-light: 241 245 249;
    --color-dark: 30 41 59
}

.theme-3.dark {
    --color-primary: 21 94 117
}

.theme-4 {
    --color-theme-1: 55 48 163;
    --color-theme-2: 49 46 129;
    --color-primary: 49 46 129;
    --color-secondary: 226 232 240;
    --color-success: 5 150 105;
    --color-info: 6 182 212;
    --color-warning: 234 179 8;
    --color-pending: 234 88 12;
    --color-danger: 185 28 28;
    --color-light: 241 245 249;
    --color-dark: 30 41 59
}

.theme-4.dark {
    --color-primary: 67 56 202
}

*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style:
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}

.\!box {
    box-shadow: 0 3px 5px #0000000b !important;
    background-color: #fff !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: .6rem !important;
    position: relative !important
}

.box {
    box-shadow: 0 3px 5px #0000000b;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: .6rem;
    position: relative
}

.dark .box {
    background-color: rgb(var(--color-darkmode-600) / 1);
    border-color: rgb(var(--color-darkmode-500) / 1)
}

.dark .\!box {
    background-color: rgb(var(--color-darkmode-600) / 1) !important;
    border-color: rgb(var(--color-darkmode-500) / 1) !important
}

.dark .box--stacked:before {
    background-color: rgb(var(--color-darkmode-600) / 70%);
    border-color: #64748b99
}

.image-fit {
    position: relative
}

.image-fit>img {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 100%
}

@keyframes type-animation {
    50% {
        opacity: 1
    }
}

.zoom-in {
    transition-property: transform, box-shadow;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    cursor: pointer
}

.zoom-in:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a
}

.visible {
    visibility: visible
}

.invisible {
    visibility: hidden
}

.static {
    position: static
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.-inset-x-\[20px\] {
    left: -20px;
    right: -20px
}

.-inset-y-\[20px\] {
    top: -20px;
    bottom: -20px
}

.inset-x-0 {
    left: 0;
    right: 0
}

.inset-y-0 {
    top: 0;
    bottom: 0
}

.-end-5 {
    inset-inline-end: -1.25rem
}

.-top-5 {
    top: -1.25rem
}

.bottom-0 {
    bottom: 0
}

.bottom-\[100\%\] {
    bottom: 100%
}

.left-0 {
    left: 0
}

.left-\[100\%\] {
    left: 100%
}

.left-\[50\%\] {
    left: 50%
}

.right-0 {
    right: 0
}

.right-\[100\%\] {
    right: 100%
}

.top-0 {
    top: 0
}

.top-6 {
    top: 1.5rem
}

.top-\[100\%\] {
    top: 100%
}

.top-\[15px\] {
    top: 15px
}

.top-\[50\%\] {
    top: 50%
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.z-30 {
    z-index: 30
}

.z-50 {
    z-index: 50
}

.z-\[100\] {
    z-index: 100
}

.z-\[10\] {
    z-index: 10
}

.z-\[51\] {
    z-index: 51
}

.z-\[60\] {
    z-index: 60
}

.z-\[9999\] {
    z-index: 9999
}

.col-span-10 {
    grid-column: span 10 / span 10
}

.col-span-12 {
    grid-column: span 12 / span 12
}

.col-span-2 {
    grid-column: span 2 / span 2
}

.col-span-3 {
    grid-column: span 3 / span 3
}

.col-span-4 {
    grid-column: span 4 / span 4
}

.col-span-6 {
    grid-column: span 6 / span 6
}

.col-span-8 {
    grid-column: span 8 / span 8
}

.m-0 {
    margin: 0
}

.m-auto {
    margin: auto
}

.-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem
}

.-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem
}

.-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
}

.-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
}

.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}

.my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem
}

.my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
}

.my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
}

.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto
}

.-mb-px {
    margin-bottom: -1px
}

.-ml-1 {
    margin-left: -.25rem
}

.-ml-\[100\%\] {
    margin-left: -100%
}

.-mr-\[100\%\] {
    margin-right: -100%
}

.-mt-1 {
    margin-top: -.25rem
}

.-mt-10 {
    margin-top: -2.5rem
}

.-mt-16 {
    margin-top: -4rem
}

.-mt-2 {
    margin-top: -.5rem
}

.-mt-\[7px\] {
    margin-top: -7px
}

.mb-0 {
    margin-bottom: 0
}

.mb-0\.5 {
    margin-bottom: .125rem
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-5 {
    margin-bottom: 1.25rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mb-\[6px\] {
    margin-bottom: 6px
}

.mb-\[8px\] {
    margin-bottom: 8px
}

.me-2 {
    margin-inline-end: .5rem
}

.ml-1 {
    margin-left: .25rem
}

.ml-1\.5 {
    margin-left: .375rem
}

.ml-2 {
    margin-left: .5rem
}

.ml-3 {
    margin-left: .75rem
}

.ml-4 {
    margin-left: 1rem
}

.ml-5 {
    margin-left: 1.25rem
}

.ml-\[140px\] {
    margin-left: 140px
}

.ml-auto {
    margin-left: auto
}

.mr-0 {
    margin-right: 0
}

.mr-1 {
    margin-right: .25rem
}

.mr-10 {
    margin-right: 2.5rem
}

.mr-2 {
    margin-right: .5rem
}

.mr-3 {
    margin-right: .75rem
}

.mr-4 {
    margin-right: 1rem
}

.mr-5 {
    margin-right: 1.25rem
}

.mr-\[10px\] {
    margin-right: 10px
}

.mr-\[2px\] {
    margin-right: 2px
}

.mr-\[60px\] {
    margin-right: 60px
}

.mr-\[8px\] {
    margin-right: 8px
}

.mr-auto {
    margin-right: auto
}

.ms-1 {
    margin-inline-start: .25rem
}

.ms-auto {
    margin-inline-start: auto
}

.mt-0 {
    margin-top: 0
}

.mt-0\.5 {
    margin-top: .125rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-12 {
    margin-top: 3rem
}

.mt-16 {
    margin-top: 4rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-2\.5 {
    margin-top: .625rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mt-6 {
    margin-top: 1.5rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-\[\.5rem\] {
    margin-top: .5rem
}

.mt-\[10px\] {
    margin-top: 10px
}

.mt-\[30px\] {
    margin-top: 30px
}

.mt-\[3px\] {
    margin-top: 3px
}

.mt-\[40px\] {
    margin-top: 40px
}

.mt-\[52px\] {
    margin-top: 52px
}

.mt-px {
    margin-top: 1px
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-0 {
    height: 0px
}

.h-10 {
    height: 2.5rem
}

.h-12 {
    height: 3rem
}

.h-16 {
    height: 4rem
}

.h-2 {
    height: .5rem
}

.h-3 {
    height: .75rem
}

.h-4 {
    height: 1rem
}

.h-48 {
    height: 12rem
}

.h-5 {
    height: 1.25rem
}

.h-52 {
    height: 13rem
}

.h-56 {
    height: 14rem
}

.h-6 {
    height: 1.5rem
}

.h-7 {
    height: 1.75rem
}

.h-8 {
    height: 2rem
}

.h-96 {
    height: 24rem
}

.h-\[182px\] {
    height: 182px
}

.h-\[20px\] {
    height: 20px
}

.h-\[22px\] {
    height: 22px
}

.h-\[24px\] {
    height: 24px
}

.h-\[26px\] {
    height: 26px
}

.h-\[30px\] {
    height: 30px
}

.h-\[32px\] {
    height: 32px
}

.h-\[34px\] {
    height: 34px
}

.h-\[350px\] {
    height: 350px
}

.h-\[35px\] {
    height: 35px
}

.h-\[38px\] {
    height: 38px
}

.h-\[40px\] {
    height: 40px
}

.h-\[440px\] {
    height: 440px
}

.h-\[45px\] {
    height: 45px
}

.h-\[50px\] {
    height: 50px
}

.h-\[58px\] {
    height: 58px
}

.h-\[59\.2px\] {
    height: 59.2px
}

.h-\[59px\] {
    height: 59px
}

.h-\[60px\] {
    height: 60px
}

.h-\[67px\] {
    height: 67px
}

.h-\[70px\] {
    height: 70px
}

.h-\[80px\] {
    height: 80px
}

.h-\[calc\(100\%_-_47px\)\] {
    height: calc(100% - 47px)
}

.h-auto {
    height: auto
}

.h-fit {
    height: -moz-fit-content;
    height: fit-content
}

.h-full {
    height: 100%
}

.h-px {
    height: 1px
}

.h-screen {
    height: 100vh
}

.max-h-0 {
    max-height: 0px
}

.max-h-\[2000px\] {
    max-height: 2000px
}

.max-h-\[200px\] {
    max-height: 200px
}

.max-h-\[210px\] {
    max-height: 210px
}

.max-h-\[350px\] {
    max-height: 350px
}

.min-h-\[100px\] {
    min-height: 100px
}

.min-h-\[102px\] {
    min-height: 102px
}

.min-h-\[130px\] {
    min-height: 130px
}

.min-h-\[132px\] {
    min-height: 132px
}

.min-h-\[150px\] {
    min-height: 150px
}

.min-h-\[212px\] {
    min-height: 212px
}

.min-h-\[252px\] {
    min-height: 252px
}

.min-h-\[300px\] {
    min-height: 300px
}

.min-h-\[32px\] {
    min-height: 32px
}

.min-h-\[37\.16px\] {
    min-height: 37.16px
}

.min-h-\[38px\] {
    min-height: 38px
}

.min-h-\[80px\] {
    min-height: 80px
}

.min-h-\[98vh\] {
    min-height: 98vh
}

.min-h-full {
    min-height: 100%
}

.min-h-screen {
    min-height: 100vh
}

.w-0 {
    width: 0px
}

.w-1\/2 {
    width: 50%
}

.w-10 {
    width: 2.5rem
}

.w-12 {
    width: 3rem
}

.w-16 {
    width: 4rem
}

.w-2 {
    width: .5rem
}

.w-20 {
    width: 5rem
}

.w-24 {
    width: 6rem
}

.w-3 {
    width: .75rem
}

.w-32 {
    width: 8rem
}

.w-4 {
    width: 1rem
}

.w-40 {
    width: 10rem
}

.w-48 {
    width: 12rem
}

.w-5 {
    width: 1.25rem
}

.w-56 {
    width: 14rem
}

.w-6 {
    width: 1.5rem
}

.w-60 {
    width: 15rem
}

.w-72 {
    width: 18rem
}

.w-80 {
    width: 28rem
}

.w-8 {
    width: 2rem
}

.w-\[100px\] {
    width: 100px
}

.w-\[10px\] {
    width: 10px
}

.w-\[124px\] {
    width: 124px
}

.w-\[130px\] {
    width: 130px
}

.w-\[144px\] {
    width: 144px
}

.w-\[270px\] {
    width: 270px
}

.w-\[280px\] {
    width: 280px
}

.w-\[32px\] {
    width: 32px
}

.w-\[38px\] {
    width: 38px
}

.w-\[40px\] {
    width: 40px
}

.w-\[450px\] {
    width: 450px
}

.w-\[500px\] {
    width: 500px
}

.w-\[60px\] {
    width: 60px
}

.w-\[80px\] {
    width: 80px
}

.w-\[90\%\] {
    width: 90%
}

.w-\[90px\] {
    width: 90px
}

.w-auto {
    width: auto
}

.w-fit {
    width: -moz-fit-content;
    width: fit-content
}

.w-full {
    width: 100%
}

.min-w-0 {
    min-width: 0px
}

.min-w-48 {
    min-width: 12rem
}

.min-w-56 {
    min-width: 14rem
}

.min-w-\[130px\] {
    min-width: 130px
}

.min-w-\[132px\] {
    min-width: 132px
}

.min-w-\[140px\] {
    min-width: 140px
}

.min-w-\[144px\] {
    min-width: 144px
}

.min-w-\[160px\] {
    min-width: 160px
}

.min-w-\[182px\] {
    min-width: 182px
}

.min-w-\[209px\] {
    min-width: 209px
}

.min-w-\[38px\] {
    min-width: 38px
}

.min-w-\[80px\] {
    min-width: 80px
}

.min-w-\[90px\] {
    min-width: 90px
}

.min-w-\[98vw\] {
    min-width: 98vw
}

.min-w-full {
    min-width: 100%
}

.max-w-72 {
    max-width: 18rem
}

.max-w-\[1100px\] {
    max-width: 1100px
}

.max-w-\[144px\] {
    max-width: 144px
}

.max-w-\[200px\] {
    max-width: 200px
}

.max-w-\[209px\] {
    max-width: 209px
}

.max-w-\[250px\] {
    max-width: 250px
}

.max-w-full {
    max-width: 100%
}

.flex-1 {
    flex: 1 1 0%
}

.flex-none {
    flex: none
}

.flex-grow {
    flex-grow: 1
}

.border-separate {
    border-collapse: separate
}

.border-spacing-y-\[10px\] {
    --tw-border-spacing-y: 10px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y)
}

.-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-\[-0px\] {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-\[-50\%\] {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[-1px\] {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[-68px\] {
    --tw-translate-y: -68px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[-74px\] {
    --tw-translate-y: -74px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[11px\] {
    --tw-translate-y: 11px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[1px\] {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[35px\] {
    --tw-translate-y: 35px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-auto {
    cursor: auto
}

.cursor-not-allowed {
    cursor: not-allowed
}

.cursor-pointer {
    cursor: pointer
}

.cursor-text {
    cursor: text
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.resize {
    resize: both
}

.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-col-reverse {
    flex-direction: column-reverse
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-1 {
    gap: .25rem
}

.gap-10 {
    gap: 2.5rem
}

.gap-2 {
    gap: .5rem
}

.gap-2\.5 {
    gap: .625rem
}

.gap-3 {
    gap: .75rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-8 {
    gap: 2rem
}

.gap-\[10px\] {
    gap: 10px
}

.gap-\[12px\] {
    gap: 12px
}

.gap-\[17px\] {
    gap: 17px
}

.gap-\[20px\] {
    gap: 20px
}

.gap-\[2px\] {
    gap: 2px
}

.gap-\[4px\] {
    gap: 4px
}

.gap-\[5px\] {
    gap: 5px
}

.gap-y-3 {
    row-gap: .75rem
}

.overflow-auto {
    overflow: auto
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-hidden {
    overflow-x: hidden
}

.overflow-x-scroll {
    overflow-x: scroll
}

.overflow-y-scroll {
    overflow-y: scroll
}

.scroll-smooth {
    scroll-behavior: smooth
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.whitespace-nowrap {
    white-space: nowrap
}

.rounded {
    border-radius: .25rem
}

.rounded-2xl {
    border-radius: 1rem
}

.rounded-\[1\.3rem\] {
    border-radius: 1.3rem
}

.rounded-\[10px\] {
    border-radius: 10px
}

.rounded-\[3px\] {
    border-radius: 3px
}

.rounded-\[6px\] {
    border-radius: 6px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-md {
    border-radius: .375rem
}

.rounded-none {
    border-radius: 0
}

.rounded-sm {
    border-radius: .125rem
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem
}

.rounded-br-2xl {
    border-bottom-right-radius: 1rem
}

.rounded-tl-2xl {
    border-top-left-radius: 1rem
}

.border {
    border-width: 1px
}

.border-0 {
    border-width: 0px
}

.border-2,
.border-\[2px\] {
    border-width: 2px
}

.border-x-0 {
    border-left-width: 0px;
    border-right-width: 0px
}

.border-b {
    border-bottom-width: 1px
}

.border-b-0 {
    border-bottom-width: 0px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-b-\[1px\] {
    border-bottom-width: 1px
}

.border-r {
    border-right-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-dashed {
    border-style: dashed
}

.border-none {
    border-style: none
}

.border-\[\#0077b5\] {
    --tw-border-opacity: 1;
    border-color: rgb(0 119 181 / var(--tw-border-opacity))
}

.border-\[\#3F4254\] {
    --tw-border-opacity: 1;
    border-color: rgb(63 66 84 / var(--tw-border-opacity))
}

.border-\[\#3b5998\] {
    --tw-border-opacity: 1;
    border-color: rgb(59 89 152 / var(--tw-border-opacity))
}

.border-\[\#4ab3f4\] {
    --tw-border-opacity: 1;
    border-color: rgb(74 179 244 / var(--tw-border-opacity))
}

.border-\[\#517fa4\] {
    --tw-border-opacity: 1;
    border-color: rgb(81 127 164 / var(--tw-border-opacity))
}

.border-danger {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-danger) / var(--tw-border-opacity))
}

.border-dark {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-dark) / var(--tw-border-opacity))
}

.border-dark\/40 {
    border-color: rgb(var(--color-dark) / .4)
}

.border-pending {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-pending) / var(--tw-border-opacity))
}

.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.border-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-secondary) / var(--tw-border-opacity))
}

.border-secondary\/70 {
    border-color: rgb(var(--color-secondary) / .7)
}

.border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.border-slate-200\/60 {
    border-color: #e2e8f099
}

.border-success {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-success) / var(--tw-border-opacity))
}

.border-transparent {
    border-color: transparent
}

.border-warning {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-warning) / var(--tw-border-opacity))
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-white\/\[0\.08\] {
    border-color: #ffffff14
}

.border-b-primary {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.border-b-transparent {
    border-bottom-color: transparent
}

.border-opacity-5 {
    --tw-border-opacity: .05
}

.bg-\[\#0077b5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 119 181 / var(--tw-bg-opacity))
}

.bg-\[\#17191E\] {
    --tw-bg-opacity: 1;
    background-color: rgb(23 25 30 / var(--tw-bg-opacity))
}

.bg-\[\#3b5998\] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 89 152 / var(--tw-bg-opacity))
}

.bg-\[\#4ab3f4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(74 179 244 / var(--tw-bg-opacity))
}

.bg-\[\#517fa4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(81 127 164 / var(--tw-bg-opacity))
}

.bg-\[\#E8FFF3\] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 255 243 / var(--tw-bg-opacity))
}

.bg-\[\#EBEFF2\] {
    --tw-bg-opacity: 1;
    background-color: rgb(235 239 242 / var(--tw-bg-opacity))
}

.bg-\[\#EEF6FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(238 246 255 / var(--tw-bg-opacity))
}

.bg-\[\#F2EBFE\] {
    --tw-bg-opacity: 1;
    background-color: rgb(242 235 254 / var(--tw-bg-opacity))
}

.bg-\[\#F4F6FA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 246 250 / var(--tw-bg-opacity))
}

.bg-\[\#F9F9F9\] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity))
}

.bg-\[\#FFF5F8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 245 248 / var(--tw-bg-opacity))
}

.bg-\[\#FFF8DD\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 248 221 / var(--tw-bg-opacity))
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-black\/10 {
    background-color: #0000001a
}

.bg-black\/30 {
    background-color: #0000004d
}

.bg-black\/60 {
    background-color: #0009
}

.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity))
}

.bg-danger {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-danger) / var(--tw-bg-opacity))
}

.bg-danger\/10 {
    background-color: rgb(var(--color-danger) / .1)
}

.bg-danger\/5 {
    background-color: rgb(var(--color-danger) / .05)
}

.bg-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-dark) / var(--tw-bg-opacity))
}

.bg-dark\/\[0\.08\] {
    background-color: rgb(var(--color-dark) / .08)
}

.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))
}

.bg-info {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-info) / var(--tw-bg-opacity))
}

.bg-info\/15 {
    background-color: rgb(var(--color-info) / .15)
}

.bg-light {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-light) / var(--tw-bg-opacity))
}

.bg-pending {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-pending) / var(--tw-bg-opacity))
}

.bg-pending\/10 {
    background-color: rgb(var(--color-pending) / .1)
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity))
}

.bg-primary\/10 {
    background-color: rgb(var(--color-primary) / .1)
}

.bg-primary\/80 {
    background-color: rgb(var(--color-primary) / .8)
}

.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))
}

.bg-secondary\/70 {
    background-color: rgb(var(--color-secondary) / .7)
}

.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}

.bg-slate-200\/60 {
    background-color: #e2e8f099
}

.bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity))
}

.bg-slate-300\/50 {
    background-color: #cbd5e180
}

.bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity))
}

.bg-success {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-success) / var(--tw-bg-opacity))
}

.bg-success\/20 {
    background-color: rgb(var(--color-success) / .2)
}

.bg-success\/5 {
    background-color: rgb(var(--color-success) / .05)
}

.bg-success\/80 {
    background-color: rgb(var(--color-success) / .8)
}

.bg-transparent {
    background-color: transparent
}

.bg-warning {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-warning) / var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-white\/\[0\.08\] {
    background-color: #ffffff14
}

.bg-opacity-10 {
    --tw-bg-opacity: .1
}

.bg-opacity-20 {
    --tw-bg-opacity: .2
}

.bg-opacity-25 {
    --tw-bg-opacity: .25
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.from-theme-1 {
    --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-theme-2 {
    --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position)
}

.bg-contain {
    background-size: contain
}

.bg-center {
    background-position: center
}

.bg-no-repeat {
    background-repeat: no-repeat
}

.stroke-1\.5 {
    stroke-width: 1.5
}

.object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.p-0 {
    padding: 0
}

.p-1 {
    padding: .25rem
}

.p-10 {
    padding: 2.5rem
}

.p-2 {
    padding: .5rem
}

.p-3 {
    padding: .75rem
}

.p-4 {
    padding: 1rem
}

.p-5 {
    padding: 1.25rem
}

.p-6 {
    padding: 1.5rem
}

.p-\[30px\] {
    padding: 30px
}

.p-\[5px\] {
    padding: 5px
}

.p-px {
    padding: 1px
}

.px-0 {
    padding-left: 0;
    padding-right: 0
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.px-\[12px\] {
    padding-left: 12px;
    padding-right: 12px
}

.px-\[20px\] {
    padding-left: 20px;
    padding-right: 20px
}

.px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0
}

.py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem
}

.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.pb-0 {
    padding-bottom: 0
}

.pb-1 {
    padding-bottom: .25rem
}

.pb-10 {
    padding-bottom: 2.5rem
}

.pb-16 {
    padding-bottom: 4rem
}

.pb-2 {
    padding-bottom: .5rem
}

.pb-28 {
    padding-bottom: 7rem
}

.pb-3 {
    padding-bottom: .75rem
}

.pb-4 {
    padding-bottom: 1rem
}

.pb-5 {
    padding-bottom: 1.25rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pb-8 {
    padding-bottom: 2rem
}

.pl-0\.5 {
    padding-left: .125rem
}

.pl-1 {
    padding-left: .25rem
}

.pl-2 {
    padding-left: .5rem
}

.pl-4 {
    padding-left: 1rem
}

.pl-5 {
    padding-left: 1.25rem
}

.pl-6 {
    padding-left: 1.5rem
}

.pr-0 {
    padding-right: 0
}

.pr-1 {
    padding-right: .25rem
}

.pr-10 {
    padding-right: 2.5rem
}

.pr-14 {
    padding-right: 3.5rem
}

.pr-16 {
    padding-right: 4rem
}

.pr-4 {
    padding-right: 1rem
}

.pr-8 {
    padding-right: 2rem
}

.pt-0 {
    padding-top: 0
}

.pt-16 {
    padding-top: 4rem
}

.pt-3 {
    padding-top: .75rem
}

.pt-4 {
    padding-top: 1rem
}

.pt-5 {
    padding-top: 1.25rem
}

.pt-6 {
    padding-top: 1.5rem
}

.pt-7 {
    padding-top: 1.75rem
}

.pt-8 {
    padding-top: 2rem
}

.pt-\[15px\] {
    padding-top: 15px
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-end {
    text-align: end
}

.align-top {
    vertical-align: top
}

.align-middle {
    vertical-align: middle
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-8xl {
    font-size: 6rem;
    line-height: 1
}

.text-\[12px\] {
    font-size: 12px
}

.text-\[13px\] {
    font-size: 13px
}

.text-\[14px\] {
    font-size: 14px
}

.text-\[15px\] {
    font-size: 15px
}

.text-\[16px\] {
    font-size: 16px
}

.text-\[18px\] {
    font-size: 18px
}

.text-\[20px\] {
    font-size: 20px
}

.text-\[24px\] {
    font-size: 24px
}

.text-\[26px\] {
    font-size: 26px
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.font-bold {
    font-weight: 700
}

.font-medium {
    font-weight: 500
}

.font-normal {
    font-weight: 400
}

.font-semibold {
    font-weight: 600
}

.leading-3 {
    line-height: .75rem
}

.leading-4 {
    line-height: 1rem
}

.leading-7 {
    line-height: 1.75rem
}

.leading-\[10px\] {
    line-height: 10px
}

.leading-\[12px\] {
    line-height: 12px
}

.leading-\[14px\] {
    line-height: 14px
}

.leading-\[16px\] {
    line-height: 16px
}

.leading-\[18px\] {
    line-height: 18px
}

.leading-\[26px\] {
    line-height: 26px
}

.leading-normal {
    line-height: 1.5
}

.leading-relaxed {
    line-height: 1.625
}

.leading-tight {
    line-height: 1.25
}

.tracking-\[-0\.28px\] {
    letter-spacing: -.28px
}

.tracking-\[-0\.32px\] {
    letter-spacing: -.32px
}

.text-\[\#181C32\] {
    --tw-text-opacity: 1;
    color: rgb(24 28 50 / var(--tw-text-opacity))
}

.text-\[\#3E97FF\] {
    --tw-text-opacity: 1;
    color: rgb(62 151 255 / var(--tw-text-opacity))
}

.text-\[\#3F4254\] {
    --tw-text-opacity: 1;
    color: rgb(63 66 84 / var(--tw-text-opacity))
}

.text-\[\#47BE7D\] {
    --tw-text-opacity: 1;
    color: rgb(71 190 125 / var(--tw-text-opacity))
}

.text-\[\#50CD89\] {
    --tw-text-opacity: 1;
    color: rgb(80 205 137 / var(--tw-text-opacity))
}

.text-\[\#5E6278\] {
    --tw-text-opacity: 1;
    color: rgb(94 98 120 / var(--tw-text-opacity))
}

.text-\[\#67759A\] {
    --tw-text-opacity: 1;
    color: rgb(103 117 154 / var(--tw-text-opacity))
}

.text-\[\#78829D\] {
    --tw-text-opacity: 1;
    color: rgb(120 130 157 / var(--tw-text-opacity))
}

.text-\[\#7E8299\] {
    --tw-text-opacity: 1;
    color: rgb(126 130 153 / var(--tw-text-opacity))
}

.text-\[\#919DA8\] {
    --tw-text-opacity: 1;
    color: rgb(145 157 168 / var(--tw-text-opacity))
}

.text-\[\#9F7AEA\] {
    --tw-text-opacity: 1;
    color: rgb(159 122 234 / var(--tw-text-opacity))
}

.text-\[\#A1A5B7\] {
    --tw-text-opacity: 1;
    color: rgb(161 165 183 / var(--tw-text-opacity))
}

.text-\[\#D9214E\] {
    --tw-text-opacity: 1;
    color: rgb(217 33 78 / var(--tw-text-opacity))
}

.text-\[\#F6C000\] {
    --tw-text-opacity: 1;
    color: rgb(246 192 0 / var(--tw-text-opacity))
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity))
}

.text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity))
}

.text-danger {
    --tw-text-opacity: 1;
    color: rgb(var(--color-danger) / var(--tw-text-opacity))
}

.text-dark {
    --tw-text-opacity: 1;
    color: rgb(var(--color-dark) / var(--tw-text-opacity))
}

.text-dark\/60 {
    color: rgb(var(--color-dark) / .6)
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity))
}

.text-pending {
    --tw-text-opacity: 1;
    color: rgb(var(--color-pending) / var(--tw-text-opacity))
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(var(--color-primary) / var(--tw-text-opacity))
}

.text-primary\/80 {
    color: rgb(var(--color-primary) / .8)
}

.text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity))
}

.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.text-slate-400\/90 {
    color: #94a3b8e6
}

.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity))
}

.text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.text-slate-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))
}

.text-success {
    --tw-text-opacity: 1;
    color: rgb(var(--color-success) / var(--tw-text-opacity))
}

.text-warning {
    --tw-text-opacity: 1;
    color: rgb(var(--color-warning) / var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-white\/70 {
    color: #ffffffb3
}

.text-white\/90 {
    color: #ffffffe6
}

.text-opacity-70 {
    --tw-text-opacity: .7
}

.opacity-0 {
    opacity: 0
}

.opacity-100 {
    opacity: 1
}

.opacity-40 {
    opacity: .4
}

.opacity-80 {
    opacity: .8
}

.shadow-\[0px_0px_0px_2px_\#fff\,_1px_1px_5px_rgba\(0\,0\,0\,0\.32\)\] {
    --tw-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, .32);
    --tw-shadow-colored: 0px 0px 0px 2px var(--tw-shadow-color), 1px 1px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-\[0px_3px_10px_\#00000017\] {
    --tw-shadow: 0px 3px 10px #00000017;
    --tw-shadow-colored: 0px 3px 10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-\[0px_3px_20px_\#0000000b\] {
    --tw-shadow: 0px 3px 20px #0000000b;
    --tw-shadow-colored: 0px 3px 20px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-\[5px_3px_5px_\#00000005\] {
    --tw-shadow: 5px 3px 5px #00000005;
    --tw-shadow-colored: 5px 3px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.outline-danger {
    outline-color: rgb(var(--color-danger) / 1)
}

.outline-dark {
    outline-color: rgb(var(--color-dark) / 1)
}

.outline-pending {
    outline-color: rgb(var(--color-pending) / 1)
}

.outline-primary {
    outline-color: rgb(var(--color-primary) / 1)
}

.outline-secondary {
    outline-color: rgb(var(--color-secondary) / 1)
}

.outline-success {
    outline-color: rgb(var(--color-success) / 1)
}

.outline-warning {
    outline-color: rgb(var(--color-warning) / 1)
}

.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-\[width\] {
    transition-property: width;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.duration-100 {
    transition-duration: .1s
}

.duration-150 {
    transition-duration: .15s
}

.duration-200 {
    transition-duration: .2s
}

.duration-300 {
    transition-duration: .3s
}

.duration-500 {
    transition-duration: .5s
}

.duration-\[400ms\] {
    transition-duration: .4s
}

.ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.ease-linear {
    transition-timing-function: linear
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.content-\[\'\'\] {
    --tw-content: "";
    content: var(--tw-content)
}

.font-inter {
    font-family: Inter, sans-serif
}

.font-nunito {
    font-family: Nunito Sans, sans-serif
}

.ant-tabs-nav:before {
    border: none !important
}

.ant-select-selector {
    max-height: 30px !important
}

.ant-select-selection-search-input {
    box-shadow: none !important
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-zl9ks2).ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
    opacity: 0
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgb(var(--color-danger) / var(--tw-border-opacity)) !important
}

.ant-tabs-nav {
    margin: 0 !important
}

.ant-tabs-tab {
    padding: 0 !important
}

.ant-tabs-tab-btn {
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    display: none !important
}

.swal2-title {
    line-height: normal !important
}

@media (min-width: 1400px) {
    .container {
        max-width: 1300px
    }
}

.shadow-none {
    box-shadow: none !important
}

.not-hover {
    background-color: #fff !important;
    cursor: default !important
}

.react-select-2-input {
    border: none !important;
    box-shadow: none !important;
    min-width: 0px !important;
    outline: none !important;
    text-decoration: none !important
}

.select2-selection__input:focus {
    box-shadow: none
}

input[type=text]:focus {
    box-shadow: none
}

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated,
.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    display: none !important
}

.ant-switch,
.ant-switch-small,
.ant-switch .ant-switch-small .css-dev-only-do-not-override-zl9ks2 {
    background-color: gray !important
}

.ant-switch-checked {
    background: #1677ff !important
}

.ant-tabs-tab-btn {
    height: 2rem
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    position: relative;
    z-index: 9999999999999999999999999999999999
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 0%;
    transform-origin: botton-center;
    bottom: -1px;
    z-index: 9999999999999999999999999999999999;
    background: #1677ff !important;
    transition: width .35s;
    left: 50%;
    transform: translate(-50%, -50%)
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:after {
    height: 2px;
    width: 100%
}

.shadow-custom-1 {
    box-shadow: #0000001a 0 4px 12px
}

.placeholder\:text-slate-400\/90::-moz-placeholder {
    color: #94a3b8e6
}

.placeholder\:text-slate-400\/90::placeholder {
    color: #94a3b8e6
}

.before\:invisible:before {
    content: var(--tw-content);
    visibility: hidden
}

.before\:fixed:before {
    content: var(--tw-content);
    position: fixed
}

.before\:absolute:before {
    content: var(--tw-content);
    position: absolute
}

.before\:inset-0:before {
    content: var(--tw-content);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.before\:inset-x-0:before {
    content: var(--tw-content);
    left: 0;
    right: 0
}

.before\:inset-y-0:before {
    content: var(--tw-content);
    top: 0;
    bottom: 0
}

.before\:left-0:before {
    content: var(--tw-content);
    left: 0
}

.before\:right-0:before {
    content: var(--tw-content);
    right: 0
}

.before\:top-0:before {
    content: var(--tw-content);
    top: 0
}

.before\:top-\[-2px\]:before {
    content: var(--tw-content);
    top: -2px
}

.before\:z-10:before {
    content: var(--tw-content);
    z-index: 10
}

.before\:z-\[-1\]:before {
    content: var(--tw-content);
    z-index: -1
}

.before\:mx-7:before {
    content: var(--tw-content);
    margin-left: 1.75rem;
    margin-right: 1.75rem
}

.before\:mx-auto:before {
    content: var(--tw-content);
    margin-left: auto;
    margin-right: auto
}

.before\:my-auto:before {
    content: var(--tw-content);
    margin-top: auto;
    margin-bottom: auto
}

.before\:-mb-\[16\%\]:before {
    content: var(--tw-content);
    margin-bottom: -16%
}

.before\:-ml-\[1\.125rem\]:before {
    content: var(--tw-content);
    margin-left: -1.125rem
}

.before\:-ml-\[13\%\]:before {
    content: var(--tw-content);
    margin-left: -13%
}

.before\:-mt-4:before {
    content: var(--tw-content);
    margin-top: -1rem
}

.before\:-mt-\[28\%\]:before {
    content: var(--tw-content);
    margin-top: -28%
}

.before\:mt-3:before {
    content: var(--tw-content);
    margin-top: .75rem
}

.before\:block:before {
    content: var(--tw-content);
    display: block
}

.before\:hidden:before {
    content: var(--tw-content);
    display: none
}

.before\:h-8:before {
    content: var(--tw-content);
    height: 2rem
}

.before\:h-\[14px\]:before {
    content: var(--tw-content);
    height: 14px
}

.before\:h-\[20px\]:before {
    content: var(--tw-content);
    height: 20px
}

.before\:h-\[65px\]:before {
    content: var(--tw-content);
    height: 65px
}

.before\:h-\[8px\]:before {
    content: var(--tw-content);
    height: 8px
}

.before\:h-full:before {
    content: var(--tw-content);
    height: 100%
}

.before\:h-px:before {
    content: var(--tw-content);
    height: 1px
}

.before\:h-screen:before {
    content: var(--tw-content);
    height: 100vh
}

.before\:w-\[14px\]:before {
    content: var(--tw-content);
    width: 14px
}

.before\:w-\[20px\]:before {
    content: var(--tw-content);
    width: 20px
}

.before\:w-\[57\%\]:before {
    content: var(--tw-content);
    width: 57%
}

.before\:w-\[8px\]:before {
    content: var(--tw-content);
    width: 8px
}

.before\:w-\[95\%\]:before {
    content: var(--tw-content);
    width: 95%
}

.before\:w-full:before {
    content: var(--tw-content);
    width: 100%
}

.before\:w-px:before {
    content: var(--tw-content);
    width: 1px
}

.before\:translate-y-\[35px\]:before {
    content: var(--tw-content);
    --tw-translate-y: 35px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:rotate-\[-4\.5deg\]:before {
    content: var(--tw-content);
    --tw-rotate: -4.5deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:rotate-\[-90deg\]:before {
    content: var(--tw-content);
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:transform:before {
    content: var(--tw-content);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:rounded-\[1\.3rem\]:before {
    content: var(--tw-content);
    border-radius: 1.3rem
}

.before\:rounded-\[100\%\]:before {
    content: var(--tw-content);
    border-radius: 100%
}

.before\:rounded-full:before {
    content: var(--tw-content);
    border-radius: 9999px
}

.before\:rounded-md:before {
    content: var(--tw-content);
    border-radius: .375rem
}

.before\:rounded-xl:before {
    content: var(--tw-content);
    border-radius: .75rem
}

.before\:rounded-bl:before {
    content: var(--tw-content);
    border-bottom-left-radius: .25rem
}

.before\:bg-\[\#17191E\]\/20:before {
    content: var(--tw-content);
    background-color: #17191e33
}

.before\:bg-black:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.before\:bg-black\/90:before {
    content: var(--tw-content);
    background-color: #000000e6
}

.before\:bg-black\/\[0\.15\]:before {
    content: var(--tw-content);
    background-color: #00000026
}

.before\:bg-danger:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-danger) / var(--tw-bg-opacity))
}

.before\:bg-primary\/30:before {
    content: var(--tw-content);
    background-color: rgb(var(--color-primary) / .3)
}

.before\:bg-slate-200:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}

.before\:bg-transparent:before {
    content: var(--tw-content);
    background-color: transparent
}

.before\:bg-white\/10:before {
    content: var(--tw-content);
    background-color: #ffffff1a
}

.before\:bg-opacity-70:before {
    content: var(--tw-content);
    --tw-bg-opacity: .7
}

.before\:bg-chevron-black:before {
    content: var(--tw-content);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2300000095' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.before\:bg-chevron-white:before {
    content: var(--tw-content);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff95' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.before\:bg-\[length\:100\%\]:before {
    content: var(--tw-content);
    background-size: 100%
}

.before\:px-4:before {
    content: var(--tw-content);
    padding-left: 1rem;
    padding-right: 1rem
}

.before\:py-2:before {
    content: var(--tw-content);
    padding-top: .5rem;
    padding-bottom: .5rem
}

.before\:pt-\[100\%\]:before {
    content: var(--tw-content);
    padding-top: 100%
}

.before\:indent-0:before {
    content: var(--tw-content);
    text-indent: 0px
}

.before\:font-roboto:before {
    content: var(--tw-content);
    font-family: Roboto
}

.before\:font-medium:before {
    content: var(--tw-content);
    font-weight: 500
}

.before\:leading-\[8px\]:before {
    content: var(--tw-content);
    line-height: 8px
}

.before\:opacity-0:before {
    content: var(--tw-content);
    opacity: 0
}

.before\:shadow-\[1px_1px_3px_rgba\(0\,0\,0\,0\.25\)\]:before {
    content: var(--tw-content);
    --tw-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
    --tw-shadow-colored: 1px 1px 3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.before\:transition-\[margin-left\]:before {
    content: var(--tw-content);
    transition-property: margin-left;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.before\:transition-opacity:before {
    content: var(--tw-content);
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.before\:duration-200:before {
    content: var(--tw-content);
    transition-duration: .2s
}

.before\:ease-in-out:before {
    content: var(--tw-content);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.before\:content-\[\'\'\]:before {
    --tw-content: "";
    content: var(--tw-content)
}

.before\:content-\[\'\@\'\]:before {
    --tw-content: "@";
    content: var(--tw-content)
}

.before\:content-\[\'HTML\'\]:before {
    --tw-content: "HTML";
    content: var(--tw-content)
}

.after\:fixed:after {
    content: var(--tw-content);
    position: fixed
}

.after\:absolute:after {
    content: var(--tw-content);
    position: absolute
}

.after\:inset-0:after {
    content: var(--tw-content);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.after\:inset-y-0:after {
    content: var(--tw-content);
    top: 0;
    bottom: 0
}

.after\:left-0:after {
    content: var(--tw-content);
    left: 0
}

.after\:z-\[-2\]:after {
    content: var(--tw-content);
    z-index: -2
}

.after\:mx-3:after {
    content: var(--tw-content);
    margin-left: .75rem;
    margin-right: .75rem
}

.after\:-mb-\[13\%\]:after {
    content: var(--tw-content);
    margin-bottom: -13%
}

.after\:-ml-\[13\%\]:after {
    content: var(--tw-content);
    margin-left: -13%
}

.after\:-mt-\[20\%\]:after {
    content: var(--tw-content);
    margin-top: -20%
}

.after\:mt-5:after {
    content: var(--tw-content);
    margin-top: 1.25rem
}

.after\:hidden:after {
    content: var(--tw-content);
    display: none
}

.after\:h-\[65px\]:after {
    content: var(--tw-content);
    height: 65px
}

.after\:w-\[57\%\]:after {
    content: var(--tw-content);
    width: 57%
}

.after\:rotate-\[-4\.5deg\]:after {
    content: var(--tw-content);
    --tw-rotate: -4.5deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.after\:transform:after {
    content: var(--tw-content);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.after\:rounded-\[100\%\]:after {
    content: var(--tw-content);
    border-radius: 100%
}

.after\:rounded-xl:after {
    content: var(--tw-content);
    border-radius: .75rem
}

.after\:bg-\[\#17191E\]:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(23 25 30 / var(--tw-bg-opacity))
}

.after\:bg-primary:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity))
}

.after\:bg-gradient-to-b:after {
    content: var(--tw-content);
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.after\:from-theme-1:after {
    content: var(--tw-content);
    --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.after\:to-theme-2:after {
    content: var(--tw-content);
    --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position)
}

.after\:shadow-md:after {
    content: var(--tw-content);
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.after\:content-\[\'\'\]:after {
    --tw-content: "";
    content: var(--tw-content)
}

.first\:-mt-4:first-child {
    margin-top: -1rem
}

.first\:mt-0:first-child {
    margin-top: 0
}

.first\:rounded-l:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.first\:rounded-l-\[0\.6rem\]:first-child {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
}

.first\:border-l:first-child {
    border-left-width: 1px
}

.last\:-mb-4:last-child {
    margin-bottom: -1rem
}

.last\:mb-0:last-child {
    margin-bottom: 0
}

.last\:rounded-r:last-child {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.last\:rounded-r-\[0\.6rem\]:last-child {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
}

.last\:border-r:last-child {
    border-right-width: 1px
}

.checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity))
}

.checked\:bg-none:checked {
    background-image: none
}

.before\:checked\:ml-\[14px\]:checked:before {
    content: var(--tw-content);
    margin-left: 14px
}

.before\:checked\:bg-white:checked:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.hover\:rotate-45:hover {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:border-primary:hover {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity))
}

.hover\:border-transparent:hover {
    border-color: transparent
}

.hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity))
}

.hover\:bg-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-dark) / var(--tw-bg-opacity))
}

.hover\:bg-dark\/10:hover {
    background-color: rgb(var(--color-dark) / .1)
}

.hover\:bg-dark\/5:hover {
    background-color: rgb(var(--color-dark) / .05)
}

.hover\:bg-slate-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.hover\:bg-slate-200\/60:hover {
    background-color: #e2e8f099
}

.hover\:bg-transparent:hover {
    background-color: transparent
}

.hover\:bg-white\/5:hover {
    background-color: #ffffff0d
}

.hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity))
}

.hover\:text-danger:hover {
    --tw-text-opacity: 1;
    color: rgb(var(--color-danger) / var(--tw-text-opacity))
}

.hover\:text-danger\/70:hover {
    color: rgb(var(--color-danger) / .7)
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(var(--color-primary) / var(--tw-text-opacity))
}

.hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity))
}

.hover\:text-slate-600:hover {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.focus\:w-72:focus {
    width: 18rem
}

.focus\:border-primary:focus {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.focus\:border-slate-600:focus {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity))
}

.focus\:border-transparent:focus {
    border-color: transparent
}

.focus\:border-opacity-40:focus {
    --tw-border-opacity: .4
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-primary:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity))
}

.focus\:ring-slate-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity))
}

.focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2
}

.focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px
}

.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed
}

.disabled\:bg-slate-100:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.disabled\:opacity-70:disabled {
    opacity: .7
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1
}

.group.mobile-menu--active .group-\[\.mobile-menu--active\]\:visible {
    visibility: visible
}

.group.mobile-menu--active .group-\[\.mobile-menu--active\]\:ml-0 {
    margin-left: 0
}

.group.mobile-menu--active .group-\[\.mobile-menu--active\]\:opacity-100 {
    opacity: 1
}

.dark\:border-\[\#0077b5\]:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(0 119 181 / var(--tw-border-opacity))
}

.dark\:border-\[\#3b5998\]:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(59 89 152 / var(--tw-border-opacity))
}

.dark\:border-\[\#4ab3f4\]:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(74 179 244 / var(--tw-border-opacity))
}

.dark\:border-\[\#517fa4\]:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(81 127 164 / var(--tw-border-opacity))
}

.dark\:border-danger:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-danger) / var(--tw-border-opacity))
}

.dark\:border-darkmode-100\/30:is(.dark *) {
    border-color: rgb(var(--color-darkmode-100) / .3)
}

.dark\:border-darkmode-100\/40:is(.dark *) {
    border-color: rgb(var(--color-darkmode-100) / .4)
}

.dark\:border-darkmode-400:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity))
}

.dark\:border-darkmode-600:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-darkmode-600) / var(--tw-border-opacity))
}

.dark\:border-darkmode-800:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-darkmode-800) / var(--tw-border-opacity))
}

.dark\:border-darkmode-800\/60:is(.dark *) {
    border-color: rgb(var(--color-darkmode-800) / .6)
}

.dark\:border-darkmode-900\/20:is(.dark *) {
    border-color: rgb(var(--color-darkmode-900) / .2)
}

.dark\:border-pending:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-pending) / var(--tw-border-opacity))
}

.dark\:border-primary:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.dark\:border-success:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-success) / var(--tw-border-opacity))
}

.dark\:border-transparent:is(.dark *) {
    border-color: transparent
}

.dark\:border-warning:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-warning) / var(--tw-border-opacity))
}

.dark\:border-white\/\[0\.08\]:is(.dark *) {
    border-color: #ffffff14
}

.dark\:border-x-darkmode-400:is(.dark *) {
    --tw-border-opacity: 1;
    border-left-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity));
    border-right-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity))
}

.dark\:border-x-transparent:is(.dark *) {
    border-left-color: transparent;
    border-right-color: transparent
}

.dark\:border-b-darkmode-600:is(.dark *) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(var(--color-darkmode-600) / var(--tw-border-opacity))
}

.dark\:border-b-primary:is(.dark *) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.dark\:border-t-darkmode-400:is(.dark *) {
    --tw-border-opacity: 1;
    border-top-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity))
}

.dark\:border-t-transparent:is(.dark *) {
    border-top-color: transparent
}

.dark\:border-opacity-100:is(.dark *) {
    --tw-border-opacity: 1
}

.dark\:border-opacity-20:is(.dark *) {
    --tw-border-opacity: .2
}

.dark\:bg-black\/20:is(.dark *) {
    background-color: #0003
}

.dark\:bg-black\/30:is(.dark *) {
    background-color: #0000004d
}

.dark\:bg-blue-900:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity))
}

.dark\:bg-darkmode-100\/20:is(.dark *) {
    background-color: rgb(var(--color-darkmode-100) / .2)
}

.dark\:bg-darkmode-400:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-400) / var(--tw-bg-opacity))
}

.dark\:bg-darkmode-400\/70:is(.dark *) {
    background-color: rgb(var(--color-darkmode-400) / .7)
}

.dark\:bg-darkmode-600:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
}

.dark\:bg-darkmode-700:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
}

.dark\:bg-darkmode-800:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity))
}

.dark\:bg-darkmode-800\/30:is(.dark *) {
    background-color: rgb(var(--color-darkmode-800) / .3)
}

.dark\:bg-darkmode-800\/90:is(.dark *) {
    background-color: rgb(var(--color-darkmode-800) / .9)
}

.dark\:bg-darkmode-900\/20:is(.dark *) {
    background-color: rgb(var(--color-darkmode-900) / .2)
}

.dark\:bg-green-900:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity))
}

.dark\:bg-primary\/20:is(.dark *) {
    background-color: rgb(var(--color-primary) / .2)
}

.dark\:bg-red-900:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity))
}

.dark\:bg-slate-200:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}

.dark\:bg-success\/10:is(.dark *) {
    background-color: rgb(var(--color-success) / .1)
}

.dark\:bg-transparent:is(.dark *) {
    background-color: transparent
}

.dark\:bg-opacity-20:is(.dark *) {
    --tw-bg-opacity: .2
}

.dark\:from-darkmode-400:is(.dark *) {
    --tw-gradient-from: rgb(var(--color-darkmode-400) / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(var(--color-darkmode-400) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.dark\:from-darkmode-800:is(.dark *) {
    --tw-gradient-from: rgb(var(--color-darkmode-800) / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(var(--color-darkmode-800) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.dark\:to-darkmode-400:is(.dark *) {
    --tw-gradient-to: rgb(var(--color-darkmode-400) / 1) var(--tw-gradient-to-position)
}

.dark\:to-darkmode-800:is(.dark *) {
    --tw-gradient-to: rgb(var(--color-darkmode-800) / 1) var(--tw-gradient-to-position)
}

.dark\:text-blue-300:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity))
}

.dark\:text-green-300:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity))
}

.dark\:text-red-300:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity))
}

.dark\:text-slate-200:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.dark\:text-slate-300:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity))
}

.dark\:text-slate-400:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.dark\:text-slate-500:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.dark\:text-white:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.dark\:shadow-\[0px_0px_0px_2px_\#3f4865\,_1px_1px_5px_rgba\(0\,0\,0\,0\.32\)\]:is(.dark *) {
    --tw-shadow: 0px 0px 0px 2px #3f4865, 1px 1px 5px rgba(0, 0, 0, .32);
    --tw-shadow-colored: 0px 0px 0px 2px var(--tw-shadow-color), 1px 1px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.dark\:placeholder\:text-slate-500\/80:is(.dark *)::-moz-placeholder {
    color: #64748bcc
}

.dark\:placeholder\:text-slate-500\/80:is(.dark *)::placeholder {
    color: #64748bcc
}

.before\:dark\:bg-darkmode-400:is(.dark *):before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-400) / var(--tw-bg-opacity))
}

.before\:dark\:bg-darkmode-400\/50:is(.dark *):before {
    content: var(--tw-content);
    background-color: rgb(var(--color-darkmode-400) / .5)
}

.before\:dark\:bg-darkmode-600:is(.dark *):before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
}

.before\:dark\:bg-darkmode-600\/30:is(.dark *):before {
    content: var(--tw-content);
    background-color: rgb(var(--color-darkmode-600) / .3)
}

.after\:dark\:bg-darkmode-600:is(.dark *):after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
}

.after\:dark\:bg-darkmode-700:is(.dark *):after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
}

.dark\:after\:from-darkmode-800:is(.dark *):after {
    content: var(--tw-content);
    --tw-gradient-from: rgb(var(--color-darkmode-800) / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(var(--color-darkmode-800) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.dark\:after\:to-darkmode-800:is(.dark *):after {
    content: var(--tw-content);
    --tw-gradient-to: rgb(var(--color-darkmode-800) / 1) var(--tw-gradient-to-position)
}

.dark\:hover\:border-transparent:hover:is(.dark *) {
    border-color: transparent
}

.dark\:hover\:bg-blue-800:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.dark\:hover\:bg-darkmode-400:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-400) / var(--tw-bg-opacity))
}

.hover\:dark\:bg-transparent:is(.dark *):hover {
    background-color: transparent
}

.dark\:hover\:text-blue-300:hover:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity))
}

.hover\:dark\:text-slate-300:is(.dark *):hover {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity))
}

.dark\:focus\:ring-slate-700:focus:is(.dark *) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity))
}

.dark\:focus\:ring-opacity-50:focus:is(.dark *) {
    --tw-ring-opacity: .5
}

.dark\:disabled\:border-transparent:disabled:is(.dark *) {
    border-color: transparent
}

.dark\:disabled\:bg-darkmode-800\/50:disabled:is(.dark *) {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

.disabled\:dark\:bg-darkmode-800\/50:is(.dark *):disabled {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

@media (min-width: 640px) {
    .sm\:col-span-2 {
        grid-column: span 2 / span 2
    }

    .sm\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .sm\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .sm\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .sm\:-mx-8 {
        margin-left: -2rem;
        margin-right: -2rem
    }

    .sm\:mb-0 {
        margin-bottom: 0
    }

    .sm\:ml-auto {
        margin-left: auto
    }

    .sm\:mr-2 {
        margin-right: .5rem
    }

    .sm\:mr-5 {
        margin-right: 1.25rem
    }

    .sm\:mr-6 {
        margin-right: 1.5rem
    }

    .sm\:mr-auto {
        margin-right: auto
    }

    .sm\:mt-0 {
        margin-top: 0
    }

    .sm\:block {
        display: block
    }

    .sm\:flex {
        display: flex
    }

    .sm\:hidden {
        display: none
    }

    .sm\:h-\[58px\] {
        height: 58px
    }

    .sm\:w-3\/4 {
        width: 75%
    }

    .sm\:w-40 {
        width: 10rem
    }

    .sm\:w-\[300px\] {
        width: 300px
    }

    .sm\:w-\[350px\] {
        width: 350px
    }

    .sm\:w-\[460px\] {
        width: 460px
    }

    .sm\:w-\[600px\] {
        width: 600px
    }

    .sm\:w-\[700px\] {
        width: 700px
    }

    .sm\:w-auto {
        width: auto
    }

    .sm\:min-w-\[40px\] {
        min-width: 40px
    }

    .sm\:flex-initial {
        flex: 0 1 auto
    }

    .sm\:flex-row {
        flex-direction: row
    }

    .sm\:flex-nowrap {
        flex-wrap: nowrap
    }

    .sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .sm\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .sm\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .sm\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .sm\:px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .sm\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .sm\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .sm\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .sm\:pb-20 {
        padding-bottom: 5rem
    }

    .sm\:text-left {
        text-align: left
    }

    .sm\:text-right {
        text-align: right
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

@media (min-width: 768px) {
    .md\:fixed {
        position: fixed
    }

    .md\:inset-x-0 {
        left: 0;
        right: 0
    }

    .md\:top-0 {
        top: 0
    }

    .md\:col-span-10 {
        grid-column: span 10 / span 10
    }

    .md\:col-span-2 {
        grid-column: span 2 / span 2
    }

    .md\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .md\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .md\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .md\:-mx-0 {
        margin-left: -0px;
        margin-right: -0px
    }

    .md\:-mt-5 {
        margin-top: -1.25rem
    }

    .md\:-mt-\[67px\] {
        margin-top: -67px
    }

    .md\:ml-0 {
        margin-left: 0
    }

    .md\:ml-10 {
        margin-left: 2.5rem
    }

    .md\:mt-0 {
        margin-top: 0
    }

    .md\:mt-1 {
        margin-top: .25rem
    }

    .md\:block {
        display: block
    }

    .md\:flex {
        display: flex
    }

    .md\:h-\[65px\] {
        height: 65px
    }

    .md\:w-72 {
        width: 18rem
    }

    .md\:min-w-\[460px\] {
        min-width: 460px
    }

    .md\:max-w-72 {
        max-width: 18rem
    }

    .md\:flex-row {
        flex-direction: row
    }

    .md\:flex-col {
        flex-direction: column
    }

    .md\:items-center {
        align-items: center
    }

    .md\:justify-start {
        justify-content: flex-start
    }

    .md\:gap-0 {
        gap: 0px
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:gap-5 {
        gap: 1.25rem
    }

    .md\:border-b-0 {
        border-bottom-width: 0px
    }

    .md\:border-l {
        border-left-width: 1px
    }

    .md\:border-r {
        border-right-width: 1px
    }

    .md\:bg-gradient-to-b {
        background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
    }

    .md\:from-slate-100 {
        --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
    }

    .md\:to-transparent {
        --tw-gradient-to: transparent var(--tw-gradient-to-position)
    }

    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .md\:px-\[22px\] {
        padding-left: 22px;
        padding-right: 22px
    }

    .md\:pl-10 {
        padding-left: 2.5rem
    }

    .md\:pl-6 {
        padding-left: 1.5rem
    }

    .md\:pt-0 {
        padding-top: 0
    }

    .md\:pt-10 {
        padding-top: 2.5rem
    }

    .md\:pt-\[80px\] {
        padding-top: 80px
    }

    .before\:md\:block:before {
        content: var(--tw-content);
        display: block
    }

    .after\:md\:block:after {
        content: var(--tw-content);
        display: block
    }

    .dark\:md\:from-darkmode-700:is(.dark *) {
        --tw-gradient-from: rgb(var(--color-darkmode-700) / 1) var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(var(--color-darkmode-700) / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
    }

    .dark\:md\:from-darkmode-800:is(.dark *) {
        --tw-gradient-from: rgb(var(--color-darkmode-800) / 1) var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(var(--color-darkmode-800) / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
    }
}

@media (min-width: 1024px) {
    .lg\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .lg\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .lg\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .lg\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .lg\:ml-auto {
        margin-left: auto
    }

    .lg\:mr-20 {
        margin-right: 5rem
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:mt-5 {
        margin-top: 1.25rem
    }

    .lg\:mt-6 {
        margin-top: 1.5rem
    }

    .lg\:block {
        display: block
    }

    .lg\:h-auto {
        height: auto
    }

    .lg\:w-2\/4 {
        width: 50%
    }

    .lg\:w-\[250px\] {
        width: 250px
    }

    .lg\:w-\[700px\] {
        width: 700px
    }

    .lg\:w-\[850px\] {
        width: 850px
    }

    .lg\:w-\[900px\] {
        width: 900px
    }

    .lg\:w-fit {
        width: -moz-fit-content;
        width: fit-content
    }

    .lg\:max-w-\[460px\] {
        max-width: 460px
    }

    .lg\:flex-row {
        flex-direction: row
    }

    .lg\:overflow-hidden {
        overflow: hidden
    }

    .lg\:overflow-visible {
        overflow: visible
    }

    .lg\:pb-0 {
        padding-bottom: 0
    }

    .lg\:pb-5 {
        padding-bottom: 1.25rem
    }

    .lg\:text-left {
        text-align: left
    }

    .lg\:text-right {
        text-align: right
    }

    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }
}

@media (min-width: 1280px) {
    .xl\:col-span-2 {
        grid-column: span 2 / span 2
    }

    .xl\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .xl\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .xl\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .xl\:my-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .xl\:-mt-\[3px\] {
        margin-top: -3px
    }

    .xl\:-mt-\[62px\] {
        margin-top: -62px
    }

    .xl\:ml-20 {
        margin-left: 5rem
    }

    .xl\:mr-3 {
        margin-right: .75rem
    }

    .xl\:mt-0 {
        margin-top: 0
    }

    .xl\:mt-24 {
        margin-top: 6rem
    }

    .xl\:mt-8 {
        margin-top: 2rem
    }

    .xl\:mt-\[62px\] {
        margin-top: 62px
    }

    .xl\:block {
        display: block
    }

    .xl\:flex {
        display: flex
    }

    .xl\:grid {
        display: grid
    }

    .xl\:hidden {
        display: none
    }

    .xl\:h-auto {
        height: auto
    }

    .xl\:min-h-\[81px\] {
        min-height: 81px
    }

    .xl\:w-32 {
        width: 8rem
    }

    .xl\:w-\[180px\] {
        width: 180px
    }

    .xl\:w-\[250px\] {
        width: 250px
    }

    .xl\:w-\[460px\] {
        width: 460px
    }

    .xl\:w-auto {
        width: auto
    }

    .xl\:min-w-\[470px\] {
        min-width: 470px
    }

    .xl\:max-w-\[460px\] {
        max-width: 460px
    }

    .xl\:flex-row {
        flex-direction: row
    }

    .xl\:flex-col {
        flex-direction: column
    }

    .xl\:justify-start {
        justify-content: flex-start
    }

    .xl\:justify-between {
        justify-content: space-between
    }

    .xl\:gap-0 {
        gap: 0px
    }

    .xl\:gap-8 {
        gap: 2rem
    }

    .xl\:overflow-hidden {
        overflow: hidden
    }

    .xl\:bg-theme-1 {
        --tw-bg-opacity: 1;
        background-color: rgb(var(--color-theme-1) / var(--tw-bg-opacity))
    }

    .xl\:bg-transparent {
        background-color: transparent
    }

    .xl\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity))
    }

    .xl\:p-0 {
        padding: 0
    }

    .xl\:p-\[30px\] {
        padding: 30px
    }

    .xl\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .xl\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .xl\:pt-\[12px\] {
        padding-top: 12px
    }

    .xl\:text-left {
        text-align: left
    }

    .xl\:text-center {
        text-align: center
    }

    .xl\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .xl\:shadow-none {
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .before\:xl\:block:before {
        content: var(--tw-content);
        display: block
    }

    .xl\:before\:block:before {
        content: var(--tw-content);
        display: block
    }

    .xl\:before\:bg-white\/10:before {
        content: var(--tw-content);
        background-color: #ffffff1a
    }

    .after\:xl\:block:after {
        content: var(--tw-content);
        display: block
    }

    .xl\:dark\:bg-darkmode-400:is(.dark *) {
        --tw-bg-opacity: 1;
        background-color: rgb(var(--color-darkmode-400) / var(--tw-bg-opacity))
    }

    .xl\:dark\:bg-darkmode-600:is(.dark *) {
        --tw-bg-opacity: 1;
        background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
    }
}

@media (min-width: 1536px) {
    .\32xl\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .\32xl\:col-span-9 {
        grid-column: span 9 / span 9
    }
}

.\[\&\.dropzone\]\:border-2.dropzone {
    border-width: 2px
}

.\[\&\.dropzone\]\:border-dashed.dropzone {
    border-style: dashed
}

.\[\&\.dropzone\]\:border-darkmode-200\/60.dropzone {
    border-color: rgb(var(--color-darkmode-200) / .6)
}

.\[\&\.dropzone\]\:dark\:border-white\/5:is(.dark *).dropzone {
    border-color: #ffffff0d
}

.\[\&\.dropzone\]\:dark\:bg-darkmode-600:is(.dark *).dropzone {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
}

.\[\&\.hljs\]\:bg-slate-50.hljs {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.\[\&\.hljs\]\:px-5.hljs {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.\[\&\.hljs\]\:py-4.hljs {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.\[\&\.hljs\]\:dark\:bg-darkmode-700:is(.dark *).hljs {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
}

.\[\&\.hljs\]\:dark\:text-slate-200:is(.dark *).hljs {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.\[\&\.hljs_\.hljs-attr\]\:dark\:text-sky-500:is(.dark *).hljs .hljs-attr {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity))
}

.\[\&\.hljs_\.hljs-name\]\:dark\:text-emerald-500:is(.dark *).hljs .hljs-name {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity))
}

.\[\&\.hljs_\.hljs-string\]\:dark\:text-slate-200:is(.dark *).hljs .hljs-string {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.\[\&\.hljs_\.hljs-tag\]\:dark\:text-slate-200:is(.dark *).hljs .hljs-tag {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.\[\&\.javascript\]\:before\:content-\[\'JS\'\].javascript:before {
    --tw-content: "JS";
    content: var(--tw-content)
}

.\[\&\.mobile-menu--active\]\:before\:visible.mobile-menu--active:before {
    content: var(--tw-content);
    visibility: visible
}

.\[\&\.mobile-menu--active\]\:before\:opacity-100.mobile-menu--active:before {
    content: var(--tw-content);
    opacity: 1
}

.\[\&\:disabled\:checked\]\:cursor-not-allowed:disabled:checked {
    cursor: not-allowed
}

.\[\&\:disabled\:checked\]\:opacity-70:disabled:checked {
    opacity: .7
}

.\[\&\:disabled\:checked\]\:dark\:bg-darkmode-800\/50:is(.dark *):disabled:checked {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

.\[\&\:disabled\:not\(\:checked\)\]\:cursor-not-allowed:disabled:not(:checked) {
    cursor: not-allowed
}

.\[\&\:disabled\:not\(\:checked\)\]\:bg-slate-100:disabled:not(:checked) {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.\[\&\:disabled\:not\(\:checked\)\]\:dark\:bg-darkmode-800\/50:is(.dark *):disabled:not(:checked) {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-slate-100:hover:not(:disabled) {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity))
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-opacity-10:hover:not(:disabled) {
    --tw-border-opacity: .1
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-opacity-90:hover:not(:disabled) {
    --tw-border-opacity: .9
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-danger\/10:hover:not(:disabled) {
    background-color: rgb(var(--color-danger) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-darkmode-800\/30:hover:not(:disabled) {
    background-color: rgb(var(--color-darkmode-800) / .3)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-pending\/10:hover:not(:disabled) {
    background-color: rgb(var(--color-pending) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-primary\/10:hover:not(:disabled) {
    background-color: rgb(var(--color-primary) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-secondary\/20:hover:not(:disabled) {
    background-color: rgb(var(--color-secondary) / .2)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-slate-100:hover:not(:disabled) {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-success\/10:hover:not(:disabled) {
    background-color: rgb(var(--color-success) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-warning\/10:hover:not(:disabled) {
    background-color: rgb(var(--color-warning) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-opacity-10:hover:not(:disabled) {
    --tw-bg-opacity: .1
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-opacity-90:hover:not(:disabled) {
    --tw-bg-opacity: .9
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-100\/20:is(.dark *):hover:not(:disabled) {
    border-color: rgb(var(--color-darkmode-100) / .2)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-300\/80:is(.dark *):hover:not(:disabled) {
    border-color: rgb(var(--color-darkmode-300) / .8)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-800:is(.dark *):hover:not(:disabled) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-darkmode-800) / var(--tw-border-opacity))
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-opacity-60:is(.dark *):hover:not(:disabled) {
    --tw-border-opacity: .6
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-100\/10:is(.dark *):hover:not(:disabled) {
    background-color: rgb(var(--color-darkmode-100) / .1)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-300\/80:is(.dark *):hover:not(:disabled) {
    background-color: rgb(var(--color-darkmode-300) / .8)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-800\/50:is(.dark *):hover:not(:disabled) {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:dark\:bg-darkmode-800\/70:is(.dark *):is(.dark *):hover:not(:disabled) {
    background-color: rgb(var(--color-darkmode-800) / .7)
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-opacity-30:is(.dark *):hover:not(:disabled) {
    --tw-bg-opacity: .3
}

.\[\&\:hover_td\]\:cursor-pointer:hover td {
    cursor: pointer
}

.\[\&\:hover_td\]\:bg-primary\/5:hover td {
    background-color: rgb(var(--color-primary) / .05)
}

.\[\&\:hover_td\]\:bg-slate-100:hover td {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.\[\&\:hover_td\]\:dark\:bg-darkmode-300:is(.dark *):hover td {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-300) / var(--tw-bg-opacity))
}

.\[\&\:hover_td\]\:dark\:bg-opacity-50:is(.dark *):hover td {
    --tw-bg-opacity: .5
}

.\[\&\:not\(\:first-child\)\]\:border-l-transparent:not(:first-child) {
    border-left-color: transparent
}

.\[\&\:not\(\:last-child\)\]\:border-b:not(:last-child) {
    border-bottom-width: 1px
}

.\[\&\:not\(\:last-child\)\]\:border-slate-200\/60:not(:last-child) {
    border-color: #e2e8f099
}

.\[\&\:not\(\:last-child\)\]\:dark\:border-darkmode-400:is(.dark *):not(:last-child) {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity))
}

.\[\&\:not\(button\)\]\:text-center:not(button) {
    text-align: center
}

.\[\&\:nth-of-type\(odd\)_td\]\:bg-slate-100:nth-of-type(odd) td {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.\[\&\:nth-of-type\(odd\)_td\]\:dark\:bg-darkmode-300:is(.dark *):nth-of-type(odd) td {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-300) / var(--tw-bg-opacity))
}

.\[\&\:nth-of-type\(odd\)_td\]\:dark\:bg-opacity-50:is(.dark *):nth-of-type(odd) td {
    --tw-bg-opacity: .5
}

.\[\&\[data-simplebar\]\]\:fixed[data-simplebar] {
    position: fixed
}

.\[\&\[readonly\]\]\:cursor-not-allowed[readonly] {
    cursor: not-allowed
}

.\[\&\[readonly\]\]\:bg-slate-100[readonly] {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.\[\&\[readonly\]\]\:dark\:border-transparent:is(.dark *)[readonly] {
    border-color: transparent
}

.\[\&\[readonly\]\]\:dark\:bg-darkmode-800\/50:is(.dark *)[readonly] {
    background-color: rgb(var(--color-darkmode-800) / .5)
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:border-primary:checked[type=checkbox] {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:border-opacity-10:checked[type=checkbox] {
    --tw-border-opacity: .1
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:bg-primary:checked[type=checkbox] {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity))
}

.\[\&\[type\=\'radio\'\]\]\:checked\:border-primary:checked[type=radio] {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-primary) / var(--tw-border-opacity))
}

.\[\&\[type\=\'radio\'\]\]\:checked\:border-opacity-10:checked[type=radio] {
    --tw-border-opacity: .1
}

.\[\&\[type\=\'radio\'\]\]\:checked\:bg-primary:checked[type=radio] {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity))
}

.\[\&_\.leaflet-tile-pane\]\:brightness-90 .leaflet-tile-pane {
    --tw-brightness: brightness(.9);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\[\&_\.leaflet-tile-pane\]\:grayscale .leaflet-tile-pane {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\[\&_\.leaflet-tile-pane\]\:hue-rotate-15 .leaflet-tile-pane {
    --tw-hue-rotate: hue-rotate(15deg);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\[\&_\.leaflet-tile-pane\]\:invert .leaflet-tile-pane {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\[\&_\.leaflet-tile-pane\]\:saturate-\[\.3\] .leaflet-tile-pane {
    --tw-saturate: saturate(.3);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\[\&_\.simplebar-scrollbar\]\:before\:bg-black\/50 .simplebar-scrollbar:before {
    content: var(--tw-content);
    background-color: #00000080
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, .7);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-toast-width: 320px;
    --toastify-toast-offset: 16px;
    --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
    --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
    --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
    --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-toast-bd-radius: 6px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    --toastify-color-progress-bgo: .2
}

.Toastify__toast-container {
    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
    position: fixed;
    padding: 4px;
    width: var(--toastify-toast-width);
    box-sizing: border-box;
    color: #fff
}

.Toastify__toast-container--top-left {
    top: var(--toastify-toast-top);
    left: var(--toastify-toast-left)
}

.Toastify__toast-container--top-center {
    top: var(--toastify-toast-top);
    left: 50%;
    transform: translate(-50%)
}

.Toastify__toast-container--top-right {
    top: var(--toastify-toast-top);
    right: var(--toastify-toast-right)
}

.Toastify__toast-container--bottom-left {
    bottom: var(--toastify-toast-bottom);
    left: var(--toastify-toast-left)
}

.Toastify__toast-container--bottom-center {
    bottom: var(--toastify-toast-bottom);
    left: 50%;
    transform: translate(-50%)
}

.Toastify__toast-container--bottom-right {
    bottom: var(--toastify-toast-bottom);
    right: var(--toastify-toast-right)
}

@media only screen and (max-width : 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: env(safe-area-inset-left);
        margin: 0
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: env(safe-area-inset-top);
        transform: translate(0)
    }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
        bottom: env(safe-area-inset-bottom);
        transform: translate(0)
    }

    .Toastify__toast-container--rtl {
        right: env(safe-area-inset-right);
        left: initial
    }
}

.Toastify__toast {
    --y: 0;
    position: relative;
    touch-action: none;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: var(--toastify-toast-bd-radius);
    box-shadow: 0 4px 12px #0000001a;
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    font-family: var(--toastify-font-family);
    cursor: default;
    direction: ltr;
    z-index: 0;
    overflow: hidden
}

.Toastify__toast--stacked {
    position: absolute;
    width: 100%;
    transform: translate3d(0, var(--y), 0) scale(var(--s));
    transition: transform .3s
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body,
.Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
    transition: opacity .1s
}

.Toastify__toast--stacked[data-collapsed=false] {
    overflow: visible
}

.Toastify__toast--stacked[data-collapsed=true]:not(:last-child)>* {
    opacity: 0
}

.Toastify__toast--stacked:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: calc(var(--g) * 1px);
    bottom: 100%
}

.Toastify__toast--stacked[data-pos=top] {
    top: 0
}

.Toastify__toast--stacked[data-pos=bot] {
    bottom: 0
}

.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
    transform-origin: top
}

.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
    transform-origin: bottom
}

.Toastify__toast--stacked:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transform: scaleY(3);
    z-index: -1
}

.Toastify__toast--rtl {
    direction: rtl
}

.Toastify__toast--close-on-click {
    cursor: pointer
}

.Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    display: flex;
    align-items: center
}

.Toastify__toast-body>div:last-child {
    word-break: break-word;
    flex: 1
}

.Toastify__toast-icon {
    margin-inline-end: 10px;
    width: 20px;
    flex-shrink: 0;
    display: flex
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: .5s
}

.Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: .3s
}

@media only screen and (max-width : 480px) {
    .Toastify__toast {
        margin-bottom: 0;
        border-radius: 0
    }
}

.Toastify__toast-theme--dark {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark)
}

.Toastify__toast-theme--light,
.Toastify__toast-theme--colored.Toastify__toast--default {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light)
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    color: var(--toastify-text-color-info);
    background: var(--toastify-color-info)
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    color: var(--toastify-text-color-success);
    background: var(--toastify-color-success)
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    color: var(--toastify-text-color-warning);
    background: var(--toastify-color-warning)
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    color: var(--toastify-text-color-error);
    background: var(--toastify-color-error)
}

.Toastify__progress-bar-theme--light {
    background: var(--toastify-color-progress-light)
}

.Toastify__progress-bar-theme--dark {
    background: var(--toastify-color-progress-dark)
}

.Toastify__progress-bar--info {
    background: var(--toastify-color-progress-info)
}

.Toastify__progress-bar--success {
    background: var(--toastify-color-progress-success)
}

.Toastify__progress-bar--warning {
    background: var(--toastify-color-progress-warning)
}

.Toastify__progress-bar--error {
    background: var(--toastify-color-progress-error)
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    background: var(--toastify-color-transparent)
}

.Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    align-self: flex-start;
    z-index: 1
}

.Toastify__close-button--light {
    color: #000;
    opacity: .3
}

.Toastify__close-button>svg {
    fill: currentColor;
    height: 16px;
    width: 14px
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1)
    }

    to {
        transform: scaleX(0)
    }
}

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--toastify-z-index);
    opacity: .7;
    transform-origin: left;
    border-bottom-left-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards
}

.Toastify__progress-bar--controlled {
    transition: transform .2s
}

.Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--wrp {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border-bottom-left-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--wrp[data-hidden=true] {
    opacity: 0
}

.Toastify__progress-bar--bg {
    opacity: var(--toastify-color-progress-bgo);
    width: 100%;
    height: 100%
}

.Toastify__spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: var(--toastify-spinner-color-empty-area);
    border-right-color: var(--toastify-spinner-color);
    animation: Toastify__spin .65s linear infinite
}

@keyframes Toastify__bounceInRight {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        transform: translate3d(10px, 0, 0)
    }

    90% {
        transform: translate3d(-5px, 0, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, var(--y), 0)
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, var(--y), 0)
    }
}

@keyframes Toastify__bounceInLeft {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0)
    }

    75% {
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        transform: translate3d(5px, 0, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, var(--y), 0)
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, var(--y), 0)
    }
}

@keyframes Toastify__bounceInUp {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, calc(var(--y) - 10px), 0)
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, calc(var(--y) + 20px), 0)
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes Toastify__bounceInDown {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0)
    }

    75% {
        transform: translate3d(0, -10px, 0)
    }

    90% {
        transform: translate3d(0, 5px, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, calc(var(--y) - 10px), 0)
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, calc(var(--y) + 20px), 0)
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0)
    }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown
}

@keyframes Toastify__zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes Toastify__zoomOut {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0;
        transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

.Toastify__zoom-enter {
    animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
    animation-name: Toastify__zoomOut
}

@keyframes Toastify__flipIn {
    0% {
        transform: perspective(400px) rotateX(90deg);
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        transform: perspective(400px) rotateX(-20deg);
        animation-timing-function: ease-in
    }

    60% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 1
    }

    80% {
        transform: perspective(400px) rotateX(-5deg)
    }

    to {
        transform: perspective(400px)
    }
}

@keyframes Toastify__flipOut {
    0% {
        transform: translate3d(0, var(--y), 0) perspective(400px)
    }

    30% {
        transform: translate3d(0, var(--y), 0) perspective(400px) rotateX(-20deg);
        opacity: 1
    }

    to {
        transform: translate3d(0, var(--y), 0) perspective(400px) rotateX(90deg);
        opacity: 0
    }
}

.Toastify__flip-enter {
    animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
    animation-name: Toastify__flipOut
}

@keyframes Toastify__slideInRight {
    0% {
        transform: translate3d(110%, 0, 0);
        visibility: visible
    }

    to {
        transform: translate3d(0, var(--y), 0)
    }
}

@keyframes Toastify__slideInLeft {
    0% {
        transform: translate3d(-110%, 0, 0);
        visibility: visible
    }

    to {
        transform: translate3d(0, var(--y), 0)
    }
}

@keyframes Toastify__slideInUp {
    0% {
        transform: translate3d(0, 110%, 0);
        visibility: visible
    }

    to {
        transform: translate3d(0, var(--y), 0)
    }
}

@keyframes Toastify__slideInDown {
    0% {
        transform: translate3d(0, -110%, 0);
        visibility: visible
    }

    to {
        transform: translate3d(0, var(--y), 0)
    }
}

@keyframes Toastify__slideOutRight {
    0% {
        transform: translate3d(0, var(--y), 0)
    }

    to {
        visibility: hidden;
        transform: translate3d(110%, var(--y), 0)
    }
}

@keyframes Toastify__slideOutLeft {
    0% {
        transform: translate3d(0, var(--y), 0)
    }

    to {
        visibility: hidden;
        transform: translate3d(-110%, var(--y), 0)
    }
}

@keyframes Toastify__slideOutDown {
    0% {
        transform: translate3d(0, var(--y), 0)
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0)
    }
}

@keyframes Toastify__slideOutUp {
    0% {
        transform: translate3d(0, var(--y), 0)
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0)
    }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft;
    animation-timing-function: ease-in;
    animation-duration: .3s
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight;
    animation-timing-function: ease-in;
    animation-duration: .3s
}

.Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp;
    animation-timing-function: ease-in;
    animation-duration: .3s
}

.Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown;
    animation-timing-function: ease-in;
    animation-duration: .3s
}

@keyframes Toastify__spin {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

.icewall .top-nav .top-menu {
    height: 55px;
    display: flex;
    align-items: center;
    margin-right: 30px;
    color: #a1a5b7;
    font-weight: 500;
    line-height: 14px;
    position: relative
}

@media (min-width: 1280px) {
    .icewall .top-nav .top-menu {
        height: 47px;
        border-radius: .5rem
    }
}

.icewall .top-nav .top-menu .top-menu__icon {
    z-index: 10
}

.icewall .top-nav .top-menu .top-menu__title {
    width: 100%;
    margin-left: .75rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    z-index: 10
}

.icewall .top-nav .top-menu .top-menu__title .top-menu__sub-icon {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    transition-duration: .1s;
    width: 1rem;
    height: 1rem;
    display: none
}

@media (min-width: 1280px) {
    .icewall .top-nav .top-menu .top-menu__title .top-menu__sub-icon {
        display: block
    }
}

.icewall .top-nav>ul>li:hover {
    position: relative
}

.icewall .top-nav>ul>li:hover>.top-menu {
    color: #fff
}

.icewall .top-nav>ul>li:hover>.top-menu:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 9999px;
    position: absolute;
    z-index: -1
}

@media (min-width: 1280px) {
    .icewall .top-nav>ul>li:hover>.top-menu:before {
        border-radius: .5rem
    }
}

.icewall .top-nav>ul>li:hover>.top-menu .top-menu__title .top-menu__sub-icon {
    transform: rotate(180deg)
}

.icewall .top-nav>ul>li:hover>ul {
    display: block
}

.icewall .top-nav>ul>li>.top-menu {
    margin-top: 3px
}

.icewall .top-nav>ul>li>.top-menu.top-menu--active:before {
    content: "";
    display: none;
    top: 57px;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: .5rem;
    position: absolute;
    border-bottom: 3px solid rgb(0 0 0 / 10%)
}

@media (min-width: 1280px) {
    .icewall .top-nav>ul>li>.top-menu.top-menu--active:before {
        display: block
    }
}

.icewall .top-nav>ul>li>.top-menu.top-menu--active:after {
    content: "";
    display: none;
    width: 20px;
    height: 80px;
    margin-bottom: -74px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(90deg);
    opacity: 0
}

@media (min-width: 1280px) {
    .icewall .top-nav>ul>li>.top-menu.top-menu--active:after {
        display: block
    }
}

.icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__icon {
    color: rgb(var(--color-theme-1) / 1)
}

@media (min-width: 1280px) {
    .icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__icon {
        color: #fff
    }
}

.icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__title {
    font-weight: 500;
    color: #1e293b
}

@media (min-width: 1280px) {
    .icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__title {
        color: #fff
    }
}

.icewall .top-nav>ul>li>.top-menu .top-menu__icon {
    margin-top: -3px
}

.icewall .top-nav>ul>li>.top-menu .top-menu__title {
    margin-top: -3px
}

.icewall .top-nav>ul>li>.top-menu .top-menu__title .top-menu__sub-icon {
    margin-left: .5rem
}

.icewall .top-nav>ul>li>ul {
    box-shadow: 0 3px 20px #0000000b;
    background-color: rgb(var(--color-theme-1) / 1);
    background-color: #000;
    display: none;
    width: 14rem;
    position: absolute;
    border-radius: .375rem;
    z-index: 20;
    padding-left: 0;
    padding-right: 0;
    margin-top: .25rem
}

.icewall .top-nav>ul>li>ul:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff0a;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: .375rem;
    z-index: -1
}

.icewall .top-nav>ul>li>ul:after {
    content: "";
    width: 100%;
    height: .25rem;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -.25rem;
    cursor: pointer
}

.icewall .top-nav>ul>li>ul>li {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative
}

.icewall .top-nav>ul>li>ul>li:hover {
    background-color: #ffffff1a;
    position: relative
}

.icewall .top-nav>ul>li>ul>li:hover>.top-menu .top-menu__title .top-menu__sub-icon {
    transform: rotate(-90deg)
}

.icewall .top-nav>ul>li>ul>li:hover>ul {
    display: block
}

.icewall .top-nav>ul>li>ul>li>.top-menu {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0
}

.icewall .top-nav>ul>li>ul>li>.top-menu .top-menu__title {
    width: 100%
}

.icewall .top-nav>ul>li>ul>li>.top-menu .top-menu__title .top-menu__sub-icon {
    margin-left: auto
}

.icewall .top-nav>ul>li>ul>li>ul {
    box-shadow: 0 3px 20px #0000000b;
    left: 100%;
    background-color: rgb(var(--color-theme-1) / 1);
    background-color: #000;
    display: none;
    border-radius: .375rem;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    width: 14rem;
    position: absolute;
    z-index: 20;
    padding-left: 0;
    padding-right: 0
}

.icewall .top-nav>ul>li>ul>li>ul:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff0a;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: .375rem;
    z-index: -1
}

.icewall .top-nav>ul>li>ul>li>ul>:hover {
    background-color: #ffffff1a
}

.icewall .top-nav>ul>li>ul>li>ul>li {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.icewall .top-nav>ul>li>ul>li>ul>li>.top-menu {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0
}

.icewall .top-nav>ul>li>ul>li>ul>li>.top-menu .top-menu__title {
    width: 100%
}

.icewall .top-nav>ul>li>ul>li>ul>li>.top-menu .top-menu__title .top-menu__sub-icon {
    margin-left: auto
}

.dark .icewall .top-nav .top-menu .top-menu__icon,
.dark .icewall .top-nav .top-menu .top-menu__title {
    color: #94a3b8
}

.dark .icewall .top-nav>ul>li:hover>.top-menu:not(.top-menu--active) {
    background-color: transparent
}

.dark .icewall .top-nav>ul>li:hover>.top-menu:not(.top-menu--active):before {
    background-color: rgb(var(--color-darkmode-700) / 1)
}

.dark .icewall .top-nav>ul>li>.top-menu.top-menu--active {
    background-color: rgb(var(--color-darkmode-700) / 1)
}

.dark .icewall .top-nav>ul>li>.top-menu.top-menu--active:before {
    background-color: rgb(var(--color-darkmode-700) / 1)
}

.dark .icewall .top-nav>ul>li>.top-menu.top-menu--active:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23232e45'/%3E%3C/svg%3E")
}

.dark .icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__icon {
    color: #fff
}

.dark .icewall .top-nav>ul>li>.top-menu.top-menu--active .top-menu__title {
    color: #fff
}

.dark .icewall .top-nav>ul>li>ul {
    background-color: rgb(var(--color-darkmode-600) / 1);
    box-shadow: 0 3px 7px #0000001c
}

.dark .icewall .top-nav>ul>li>ul:before {
    background-color: #0000001a
}

.dark .icewall .top-nav>ul>li>ul>li>ul {
    background-color: rgb(var(--color-darkmode-600) / 1);
    box-shadow: 0 3px 7px #0000001c
}

.dark .icewall .top-nav>ul>li>ul>li>ul:before {
    background-color: #0000001a
}

[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit
}

.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0
}

.simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch
}

.simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0
}

.simplebar-content:before,
.simplebar-content:after {
    content: " ";
    display: table
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden
}

[data-simplebar].simplebar-dragging,
[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #000;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity .2s .5s linear
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    transition-delay: 0s;
    transition-duration: 0s
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
}

.simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 0;
    bottom: 0;
    min-height: 0;
    min-width: 10px;
    width: auto
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0
}

.simplebar-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: scrollbar !important
}

.simplebar-dummy-scrollbar-size>div {
    width: 200%;
    height: 200%;
    margin: 10px 0
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.mobile-menu .menu__divider {
    width: 100%;
    height: 1px;
    background-color: #ffffff14;
    position: relative
}

.mobile-menu .menu {
    height: 50px;
    display: flex;
    align-items: center;
    color: #fff
}

.mobile-menu .menu .menu__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: .75rem
}

.mobile-menu .menu .menu__title .menu__sub-icon {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    transition-duration: .1s;
    margin-left: auto
}

.mobile-menu .menu .menu__title .menu__sub-icon svg {
    width: 1.25rem;
    height: 1.25rem
}

.mobile-menu ul>li>.menu {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.mobile-menu ul>li>ul {
    background: #0000001a;
    border-radius: .5rem;
    margin: .25rem 1rem
}

.mobile-menu ul>li>ul:not(.menu__sub-open) {
    display: none
}

.mobile-menu ul>li>ul>li>.menu {
    padding-left: 1rem;
    padding-right: 1rem
}

.mobile-menu ul>li>ul>li>ul {
    background: #0000001a;
    border-radius: .5rem;
    margin: .25rem auto
}

.mobile-menu ul>li>ul>li>ul:not(.menu__sub-open) {
    display: none
}

.mobile-menu ul>li>ul>li>ul>li>.menu {
    padding-left: 1rem;
    padding-right: 1rem
}

.dark .mobile-menu ul>li>ul {
    background-color: rgb(var(--color-darkmode-700) / 1)
}

.dark .mobile-menu ul>li>ul>li>ul {
    background-color: rgb(var(--color-darkmode-600) / 1)
}

.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

[data-tippy-root] {
    max-width: calc(100vw - 10px)
}

.tippy-box {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top]>.tippy-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
    right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
    left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #333
}

.tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1
}

.tippy-box[data-theme~=light] {
    color: #26323d;
    box-shadow: 0 0 20px 4px #9aa1b126, 0 4px 80px -8px #24282f40, 0 4px 4px -2px #5b5e6926;
    background-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=top]>.tippy-arrow:before {
    border-top-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=bottom]>.tippy-arrow:before {
    border-bottom-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=left]>.tippy-arrow:before {
    border-left-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=right]>.tippy-arrow:before {
    border-right-color: #fff
}

.tippy-box[data-theme~=light]>.tippy-backdrop {
    background-color: #fff
}

.tippy-box[data-theme~=light]>.tippy-svg-arrow {
    fill: #fff
}

.tippy-box[data-placement^=top]>.tippy-svg-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-svg-arrow:after,
.tippy-box[data-placement^=top]>.tippy-svg-arrow>svg {
    top: 16px;
    transform: rotate(180deg)
}

.tippy-box[data-placement^=bottom]>.tippy-svg-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-svg-arrow>svg {
    bottom: 16px
}

.tippy-box[data-placement^=left]>.tippy-svg-arrow {
    right: 0
}

.tippy-box[data-placement^=left]>.tippy-svg-arrow:after,
.tippy-box[data-placement^=left]>.tippy-svg-arrow>svg {
    transform: rotate(90deg);
    top: calc(50% - 3px);
    left: 11px
}

.tippy-box[data-placement^=right]>.tippy-svg-arrow {
    left: 0
}

.tippy-box[data-placement^=right]>.tippy-svg-arrow:after,
.tippy-box[data-placement^=right]>.tippy-svg-arrow>svg {
    transform: rotate(-90deg);
    top: calc(50% - 3px);
    right: 11px
}

.tippy-svg-arrow {
    width: 16px;
    height: 16px;
    fill: #333;
    text-align: initial
}

.tippy-svg-arrow,
.tippy-svg-arrow>svg {
    position: absolute
}

.tippy-box[data-animation=shift-away][data-state=hidden] {
    opacity: 0
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=top] {
    transform: translateY(10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=bottom] {
    transform: translateY(-10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=left] {
    transform: translate(10px)
}

.tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=right] {
    transform: translate(-10px)
}

.tooltip-content {
    left: 10000px;
    position: fixed
}

.tippy-box {
    border-radius: .375rem
}

.tippy-box .tippy-content {
    padding: .375rem .75rem
}

.tippy-popper[x-placement=left] .tippy-roundarrow,
.tippy-popper[x-placement=left] .tippy-arrow,
.tippy-popper[x-placement=right] .tippy-roundarrow,
.tippy-popper[x-placement=right] .tippy-arrow,
.tippy-popper[x-placement=left-start] .tippy-roundarrow,
.tippy-popper[x-placement=left-start] .tippy-arrow,
.tippy-popper[x-placement=left-end] .tippy-roundarrow,
.tippy-popper[x-placement=left-end] .tippy-arrow,
.tippy-popper[x-placement=right-start] .tippy-roundarrow,
.tippy-popper[x-placement=right-start] .tippy-arrow,
.tippy-popper[x-placement=right-end] .tippy-roundarrow,
.tippy-popper[x-placement=right-end] .tippy-arrow {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto
}

.tippy-popper[x-placement=top] .tippy-roundarrow,
.tippy-popper[x-placement=top] .tippy-arrow,
.tippy-popper[x-placement=bottom] .tippy-roundarrow,
.tippy-popper[x-placement=bottom] .tippy-arrow,
.tippy-popper[x-placement=top-start] .tippy-roundarrow,
.tippy-popper[x-placement=top-start] .tippy-arrow,
.tippy-popper[x-placement=top-end] .tippy-roundarrow,
.tippy-popper[x-placement=top-end] .tippy-arrow,
.tippy-popper[x-placement=bottom-start] .tippy-roundarrow,
.tippy-popper[x-placement=bottom-start] .tippy-arrow,
.tippy-popper[x-placement=bottom-end] .tippy-roundarrow,
.tippy-popper[x-placement=bottom-end] .tippy-arrow {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto
}

.dark .tippy-box {
    box-shadow: 0 0 20px 4px #00000026, 0 4px 80px -8px #24282f40, 0 4px 4px -2px #5b5e6926;
    color: #cbd5e1;
    background-color: rgb(var(--color-darkmode-300) / 1)
}

.dark .tippy-box>.tippy-svg-arrow {
    fill: rgb(var(--color-darkmode-300) / 1)
}

.ck-editor,
.document-editor,
.ck-editor__editable {
    box-shadow: 0 1px 2px #0000000d
}

.document-editor {
    display: flex;
    max-height: 700px;
    flex-flow: column nowrap;
    border-radius: var(--ck-border-radius);
    border: 1px solid #e2e8f0
}

.document-editor .document-editor__toolbar {
    z-index: 1;
    border-bottom: 1px solid #e2e8f0
}

.document-editor .document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
    background-color: #f1f5f9
}

.document-editor .document-editor__toolbar .ck-toolbar .ck-icon {
    width: 16px
}

.document-editor .document-editor__editable-container {
    overflow-y: scroll;
    padding: calc(2 * var(--ck-spacing-large));
    background-color: #f1f5f9
}

.document-editor .document-editor__editable-container .ck-editor__editable {
    width: 15.8cm;
    margin: 0 auto;
    min-height: 21cm;
    background: #fff;
    padding: 1cm 2cm 2cm;
    border-radius: var(--ck-border-radius);
    border: 1px solid #e2e8f0;
    box-shadow: 0 1px 2px #0000000d
}

.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
    min-width: 6em
}

.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(.8);
    transform-origin: left
}

.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 2.18em;
    font-weight: 400
}

.document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em
}

.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 1.75em;
    font-weight: 400;
    color: #009dff
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text)
}

.document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em
}

.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: 700
}

.document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em
}

.document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em
}

.document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc(2 * var(--ck-spacing-large));
    margin-right: calc(2 * var(--ck-spacing-large))
}

.ck.ck-content {
    min-height: 250px
}

.ck.ck-content.ck-editor__editable_inline {
    border: 1px solid #e2e8f0
}

.ck .ck .ck-content {
    min-height: 250px
}

.ck .ck .ck-content.ck-editor__editable_inline {
    border: 1px solid #e2e8f0
}

.ck .ck .ck-content blockquote,
.ck .ck .ck-content dl,
.ck .ck .ck-content dd,
.ck .ck .ck-content h1,
.ck .ck .ck-content h2,
.ck .ck .ck-content h3,
.ck .ck .ck-content h4,
.ck .ck .ck-content h5,
.ck .ck .ck-content h6,
.ck .ck .ck-content hr,
.ck .ck .ck-content figure,
.ck .ck .ck-content p,
.ck .ck .ck-content pre {
    margin: revert
}

.ck .ck .ck-content ol,
.ck .ck .ck-content ul {
    list-style: revert;
    margin: revert;
    padding: revert
}

.ck .ck .ck-content h1,
.ck .ck .ck-content h2,
.ck .ck .ck-content h3,
.ck .ck .ck-content h4,
.ck .ck .ck-content h5,
.ck .ck .ck-content h6 {
    font-size: revert;
    font-weight: revert
}

.ck .ck .ck-content img,
.ck .ck .ck-content svg,
.ck .ck .ck-content video,
.ck .ck .ck-content canvas,
.ck .ck .ck-content audio,
.ck .ck .ck-content iframe,
.ck .ck .ck-content embed,
.ck .ck .ck-content object {
    display: revert;
    vertical-align: revert
}

.ck .ck.ck-reset_all,
.ck .ck.ck-reset_all * {
    color: #1e293b
}

.ck .ck.ck-toolbar,
.ck .ck.ck-balloon-panel {
    background-color: #f1f5f9;
    border-color: #e2e8f0
}

.ck .ck.ck-toolbar .ck-icon,
.ck .ck.ck-balloon-panel .ck-icon {
    width: 16px
}

.ck .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #e2e8f0
}

.ck .ck.ck-button:not(.ck-disabled):hover,
.ck .cka.ck-button:not(.ck-disabled):hover {
    background-color: #e2e8f0
}

.dark .document-editor {
    border-color: rgb(var(--color-darkmode-900) / 30%)
}

.dark .document-editor .document-editor__toolbar {
    border-color: rgb(var(--color-darkmode-900) / 20%)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar {
    background-color: rgb(var(--color-darkmode-900) / 20%)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck-toolbar__separator {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck-tooltip .ck-tooltip__text {
    color: #e2e8f0;
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck-tooltip .ck-tooltip__text:after {
    border-bottom: rgb(var(--color-darkmode-800) / 1)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck.ck-icon :not([fill]) {
    fill: #cbd5e1
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck.ck-dropdown__panel {
    border-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .document-editor .document-editor__toolbar .ck-toolbar .ck.ck-button.ck-on,
.dark .document-editor .document-editor__toolbar .ck-toolbar a.ck.ck-button.ck-on {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .document-editor .document-editor__editable-container {
    background-color: rgb(var(--color-darkmode-600) / 1)
}

.dark .document-editor .document-editor__editable-container .ck-editor__editable,
.dark .ck.ck-content.ck-editor__editable_inline {
    border-color: rgb(var(--color-darkmode-900) / 20%);
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck.ck-list {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck.ck-dropdown__panel {
    background-color: rgb(var(--color-darkmode-800) / 1);
    border-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck.ck.ck-input-text {
    background-color: rgb(var(--color-darkmode-400) / 1);
    border-color: rgb(var(--color-darkmode-400) / 1)
}

.dark .ck.ck.ck-input-text:focus {
    box-shadow: none
}

.dark .ck .ck-insert-table-dropdown-grid-box {
    border-color: rgb(var(--color-darkmode-400) / 1)
}

.dark .ck.ck-reset_all,
.dark .ck.ck-reset_all * {
    color: #cbd5e1 !important
}

.dark .ck .ck .ck-content.ck-editor__editable_inline {
    border-color: rgb(var(--color-darkmode-900) / 20%);
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-toolbar,
.dark .ck .ck.ck-balloon-panel {
    background-color: rgb(var(--color-darkmode-700) / 1);
    border-color: rgb(var(--color-darkmode-900) / 20%)
}

.dark .ck .ck.ck-toolbar:before,
.dark .ck .ck.ck-balloon-panel:before {
    border-bottom-color: rgb(var(--color-darkmode-900) / 20%) !important
}

.dark .ck .ck.ck-toolbar:after,
.dark .ck .ck.ck-balloon-panel:after {
    border-bottom-color: rgb(var(--color-darkmode-900) / 20%) !important
}

.dark .ck .ck.ck-toolbar .ck-toolbar__separator,
.dark .ck .ck.ck-balloon-panel .ck-toolbar__separator {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-toolbar .ck-tooltip .ck-tooltip__text,
.dark .ck .ck.ck-balloon-panel .ck-tooltip .ck-tooltip__text {
    color: #e2e8f0;
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-toolbar .ck-tooltip .ck-tooltip__text:after,
.dark .ck .ck.ck-balloon-panel .ck-tooltip .ck-tooltip__text:after {
    border-bottom-color: rgb(var(--color-darkmode-900) / 20%)
}

.dark .ck .ck.ck-toolbar .ck.ck-icon :not([fill]),
.dark .ck .ck.ck-balloon-panel .ck.ck-icon :not([fill]) {
    fill: #cbd5e1
}

.dark .ck .ck.ck-toolbar .ck.ck-dropdown__panel,
.dark .ck .ck.ck-balloon-panel .ck.ck-dropdown__panel {
    border-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-toolbar .ck.ck-button.ck-on,
.dark .ck .ck.ck-toolbar a.ck.ck-button.ck-on,
.dark .ck .ck.ck-balloon-panel .ck.ck-button.ck-on,
.dark .ck .ck.ck-balloon-panel a.ck.ck-button.ck-on {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-editor__main>.ck-editor__editable {
    background-color: rgb(var(--color-darkmode-800) / 1)
}

.dark .ck .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: rgb(var(--color-darkmode-900) / 20%)
}

.dark .ck .ck.ck-button:not(.ck-disabled):hover,
.dark .ck .cka.ck-button:not(.ck-disabled):hover {
    background-color: rgb(var(--color-darkmode-900) / 20%)
}